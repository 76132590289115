import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  TText,
  Td,
  Tr
} from '../../../../components/Table/ScrollTableComponent'
import { UploadUserButton } from '../../../../components/Uploads/UploadsRowComponent/UploadsRowComponent'
import { UserType } from '../../../../core/entities/user.entity'
import { EventCalculations } from '../../EventCalculations/EventCalculations'
import { getEventConfig } from '../../functions/functions'
import { PatientLogValue } from '../../interfaces'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`
const Icon = styled(FontAwesomeIcon)<{ $color: string }>`
  font-size: 1rem;
  line-height: 2.25rem;
  color: ${(props) => props.$color};
`
const Trr = styled(Tr)`
  position: relative;
  &:hover {
    background-color: var(--element-bg);
  }
`
const Tdd = styled(Td)`
  line-height: 2.25rem;
  background-color: inherit;
`

const AlgoInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--element-bg);
  white-space: nowrap;
  justify-content: flex-start;
`
const AlgoInfoIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: var(--blue);
  line-height: 1.25rem;
`
const AlgoInfoText = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
`

export const EventsTableRow = ({ log }: { log: PatientLogValue }) => {
  const { t } = useTranslation()
  const time = dayjs(log.time)
  const config = getEventConfig(log.prediction, t)
  const [hover, setHover] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <Trr
      data-testid="eventsRow"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tdd>
        <TText>{time.format('DD/MM/YYYY')}</TText>
        <TText style={{ color: 'var(--text-lighter)' }}> @ </TText>
        <TText> {time.format('HH:mm')}</TText>
      </Tdd>

      <Tdd>
        <ContentWrapper>
          <Icon
            icon={config.content.iconSource}
            $color={config.content.iconColor}
          />
          <TText>{config.content.text}</TText>
        </ContentWrapper>
      </Tdd>
      <Tdd>
        <ContentWrapper>
          <TText>{log.userName}</TText>
          {log.you && (
            <UploadUserButton style={{ lineHeight: '100%' }}>
              {t('you')}
            </UploadUserButton>
          )}
        </ContentWrapper>
      </Tdd>
      <Tdd>
        {log.callerType !== UserType.Patient ? (
          <AlgoInfo>
            <AlgoInfoIcon icon={faInfoCircle} />
            <AlgoInfoText>
              {t('Not visible to patient - only for algo testing')}
            </AlgoInfoText>
          </AlgoInfo>
        ) : (
          <TText>–</TText>
        )}
      </Tdd>
      <EventCalculations
        hover={hover}
        showModal={showModal}
        setShowModal={setShowModal}
        algorithmInfo={log.algorithmInfo}
        probability={log.probability}
      />
    </Trr>
  )
}
