import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { ConsultationsAccountButtonProps } from '../../constants/constants'
import {
  Icon,
  Text,
  Wrapper
} from '../ConsultationsAccountLinkStyledComponents/ConsultationsAccountLinkStyledComponents'

export const DexcomConsultationsButton = ({
  provider,
  inOptionsMenu
}: ConsultationsAccountButtonProps) => {
  const { t } = useTranslation()
  return (
    <>
      {inOptionsMenu ? null : (
        <Wrapper>
          <Icon icon={faLinkSimple}></Icon>
          <Text>
            {t(
              `data-filter.patientaccountlink.${provider.providerType.toLowerCase()}`
            )}
          </Text>
        </Wrapper>
      )}
    </>
  )
}
