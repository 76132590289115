import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../../../../../core/dayjs/dayjs'

const Tooltip = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
  padding: 0.75rem 1rem;
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  position: absolute;
  top: 2rem;
  white-space: nowrap;
  z-index: 100;
  gap: 0.5rem;
  display: flex;
  align-items: center;
`

const Text = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
`
const Unit = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`
const Seperator = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  text-align: center;
  font-size: 0.5rem;
  font-weight: 900;
  line-height: 0.75rem;
`
const ValueAndUnitWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`
const NoDataText = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`
interface RecentDayInjectionItemTooltipProps {
  daysAgo: number
  total: number | null
}
export const RecentDayInjectionItemTooltip = ({
  daysAgo,
  total
}: RecentDayInjectionItemTooltipProps) => {
  const { t } = useTranslation()
  const day = dayjs(new Date()).subtract(daysAgo, 'day')
  return (
    <Tooltip>
      <Text>{day.isToday() ? t('today') : day.format('dddd')}</Text>
      <Seperator icon={faCircleSmall} />
      <Text>{day.format('DD/MM/YYYY')}</Text>
      <Seperator icon={faCircleSmall} />
      {total === null ? (
        <NoDataText>{t('no_pen_data')}</NoDataText>
      ) : (
        <ValueAndUnitWrapper>
          <Text>{total}</Text>
          <Unit>{t('u_total')}</Unit>
        </ValueAndUnitWrapper>
      )}
    </Tooltip>
  )
}
