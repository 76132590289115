import styled from 'styled-components'
import { BasalInjectionInfo } from '../../../../core/api/getDoseRecommendations'
import { InjectionsRecentDaysTimeline } from './InjectionsRecentDaysTimeline/InjectionsRecentDaysTimeline'
import { RecentDayInjectionItem } from './RecentDayInjectionItem/RecentDayInjectionItem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex:start;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

export const InjectionsRecentDays = ({
  basalInjectionInfo
}: {
  basalInjectionInfo: BasalInjectionInfo
}) => {
  return (
    <Wrapper>
      <Row>
        {basalInjectionInfo.days.map((injectionStatusForDay) => (
          <RecentDayInjectionItem
            injectionStatus={injectionStatusForDay.injectionStatus}
            daysAgo={injectionStatusForDay.daysAgo}
            total={injectionStatusForDay.total}
          />
        ))}
      </Row>
      <InjectionsRecentDaysTimeline />
    </Wrapper>
  )
}
