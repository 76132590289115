import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export enum RadioButtonState {
  check,
  unchecked,
  inactive
}

export enum RadioButtonSize {
  small,
  medium,
  large
}

interface WrapperProps {
  $active: boolean | null
  $size: RadioButtonSize
}

const getStyle = (size: RadioButtonSize) => {
  switch (size) {
    case RadioButtonSize.small:
      return { size: '1rem', borderRadius: '0.25rem', fontSize: '0.75rem' }
    case RadioButtonSize.medium:
      return { size: '1.5rem', borderRadius: '0.375rem', fontSize: '1.125rem' }
    case RadioButtonSize.large:
      return { size: '2rem', borderRadius: '0.25rem', fontSize: '1.5rem' }
  }
}
export const Wrapper = styled.div<{ $active: boolean; $size: RadioButtonSize }>`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.1875rem 0.234375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: ${(props) =>
    props.$active
      ? '0.375rem solid var(--brand-primary-color)'
      : '0.046875rem solid var(--element-stroke)'};
  background-color: var(--white-color);
  box-sizing: border-box;
`
export const Icon = styled(FontAwesomeIcon)<WrapperProps>`
  font-size: ${(props) => getStyle(props.$size).fontSize};
  color: ${(props) =>
    props.$active ? 'var(--white-color)' : 'var(--text-lighter)'};
`

interface RadioButtonProps {
  state: RadioButtonState
  size: RadioButtonSize
}
export const RadioButton = ({ state, size }: RadioButtonProps) => {
  const active = state === RadioButtonState.check
  return (
    <Wrapper $active={active} $size={size}>
      {state !== RadioButtonState.unchecked && (
        <Icon $active={active} $size={size} icon={faCheck} />
      )}
    </Wrapper>
  )
}
