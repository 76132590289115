import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { BolusInjectionWidgetData } from '../BolusInjection'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-sizing: border-box;
  background-color: var(--element-bg);
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
`
export const Title = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--text-primary);
`
export const SubTitle = styled(Title)`
  color: var(--text-lighter);
`
export const Value = styled(Title)`
  font-weight: 700;
  line-height: 0.75rem;
  font-size: 1rem;
`
export const AvgInjections = ({ data }: { data: BolusInjectionWidgetData }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t('avg_daily_amount_of_doses_bolus')}</Title>
        <SubTitle>{t('including flow check')}</SubTitle>
      </TitleWrapper>
      <Value data-testid="bolusAvgInjectionsValue">
        {formatNumber({ value: data.avgDailyInjections, decimals: 1 })}
      </Value>
    </Wrapper>
  )
}
