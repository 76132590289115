import config from '../../config'
import { AxiosRequest } from '../axios-request'

export async function uploadConsultationCsv(
  formData: FormData
): Promise<number> {
  const response = await AxiosRequest.create({
    headers: {
      'content-type': 'multipart/form-data'
    }
  }).post(config.API_URL + '/consultations_uploads', formData)
  return response.data as number
}
