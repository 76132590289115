import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from 'react'
import styled from 'styled-components'
import { DoseRecommendationState } from '../../../../core/api/getDoseRecommendations'
import {
  DoseEditorBadge,
  DoseEditorState
} from './DoseEditorBadge/DoseEditorBadge'

const Wrapper = styled.div`
  display: flex;
  width: 23.25rem;
  justify-content: center;
  height: 4.5rem;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--white-color);
  position: relative;
`
const ValueAndUnit = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 0.5rem;
  position: absolute;
  left: 50%;
  transform: translateX(-3.75rem);
`
const Value = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  all: unset;
  width: 5.25rem;
  color: var(--text-primary);
  font-family: inter;
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 3rem;
  -moz-appearance: textfield;
  text-align: right;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const Unit = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`

const IncreaseAndDecrease = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  position: relative;
  left: 0.75rem;
`
const IncreaseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  border-radius: 0.25rem 0.5rem 0.125rem 0.25rem;
  background-color: var(--element-bg);
  width: 4rem;
  box-sizing: border-box;
  height: 1.875rem;
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    color: var(--white-color);
    background-color: var(--blue);
  }
`
const DecreaseButton = styled(IncreaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  border-radius: 0.25rem 0.125rem 0.5rem 0.25rem;
  background-color: var(--element-bg);
`
const ArrowIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
  line-height: 3rem;
`

interface DoseEditorProps {
  state: DoseEditorState
  setState: Dispatch<SetStateAction<DoseEditorState>>
  nextDose: number
  setNextDose: Dispatch<SetStateAction<number>>
  originalNextDose: number
  inputRef: MutableRefObject<HTMLInputElement | null>
  doseState: DoseRecommendationState
}
const isNumber = (value: string) => {
  return /^-?\d+(\.\d+)?$/.test(value)
}

export const DoseEditor = ({
  state,
  setState,
  nextDose,
  setNextDose,
  originalNextDose,
  inputRef,
  doseState
}: DoseEditorProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === '') {
      setNextDose(0)
      return
    }
    if (
      !isNumber(event.target.value) ||
      Number.parseInt(event.target.value) < 0 ||
      Number.parseInt(event.target.value) > 999
    ) {
      event.preventDefault()
      return
    }
    setNextDose(Number.parseInt(event.target.value))
  }

  const handleIncrement = () => {
    if (nextDose < 999) {
      setNextDose((state) => state + 1)
    }
  }
  const handleDecrement = () => {
    if (nextDose > 0) {
      setNextDose((state) => state - 1)
    }
  }

  return (
    <Wrapper>
      <Container>
        <DoseEditorBadge
          state={state}
          setState={setState}
          setNextDose={setNextDose}
          originalNextDose={originalNextDose}
          doseState={doseState}
        ></DoseEditorBadge>
        <ValueAndUnit>
          <Value
            data-testid="doseEditorValue"
            ref={inputRef}
            type="number"
            value={nextDose}
            onChange={handleChange}
          />
          <Unit>U</Unit>
        </ValueAndUnit>
        <IncreaseAndDecrease>
          <IncreaseButton data-testid="increaseDose" onClick={handleIncrement}>
            <ArrowIcon icon={faArrowUp}></ArrowIcon>
          </IncreaseButton>
          <DecreaseButton data-testid="decreaseDose" onClick={handleDecrement}>
            <ArrowIcon icon={faArrowDown}></ArrowIcon>
          </DecreaseButton>
        </IncreaseAndDecrease>
      </Container>
    </Wrapper>
  )
}
