import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ConsultationsData } from '../../constants/constants'
import { NewPatientIcon } from '../NewPatient/NewPatientIcon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Text = styled.span<{ $patientExists: boolean }>`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.25rem;
  &:hover {
    color: ${(props) =>
      props.$patientExists
        ? 'var(--brand-primary-color)'
        : 'var(--text-primary)'};
    cursor: ${(props) => (props.$patientExists ? 'pointer' : 'auto')};
  }
`

export const MrnField = ({
  row,
  onPatientCreated
}: {
  row: ConsultationsData
  onPatientCreated: () => void
}) => {
  const navigate = useNavigate()
  const goToPatient = () => {
    navigate(`/portal/patients/${row.patientId}/insights`)
  }
  return (
    <Wrapper>
      <Text
        onClick={() => (row.patientId ? goToPatient() : null)}
        $patientExists={row.patientId !== null}
      >
        {row.importedMRN
          ? row.importedMRN.substring(0, 6) +
            '-' +
            row.importedMRN.substring(6, 11)
          : '-'}
      </Text>
      {!row.patientId && (
        <NewPatientIcon
          onPatientCreated={onPatientCreated}
          mrn={row.importedMRN}
          name={row.importedName}
        />
      )}
    </Wrapper>
  )
}
