import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { keyframes } from 'styled-components'

export enum UploadError {
  wrongfiletype = 'Upload Error'
}
export enum UploadSuccess {
  fileuploaded = 'Uploaded'
}

interface FileInputLabelProps {
  active: boolean
  status: UploadError | UploadSuccess | null
}
interface FileInputTitleProps {
  active: boolean
}

export const UploadComponentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`
export const UploadDataWrapper = styled.form`
  height: 12.5rem;
  width: 100%;
  text-align: center;
  position: relative;
`
export const UploadDataWrapperFixed = styled.form`
  height: 12.5rem;
  width: 18.75rem;
  max-width: 100%;
  text-align: center;
  position: relative;
`
export const FileInput = styled.input`
  display: none;
`

export const loadAnimation = () => keyframes`
0% { left: 0%;}
100% { left: calc(100% - 2rem)}
`
export const LoadWrapper = styled.div`
  width: 100%;
  height: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  background-color: var(--white-color);
  position: relative;
  overflow: hidden;
`
export const LoadBar = styled.div`
  position: absolute;
  width: 2rem;
  height: 100%;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--inRange-color);
  animation-name: ${loadAnimation};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
`
export const LoadComponent = () => {
  return (
    <LoadWrapper>
      <LoadBar></LoadBar>
    </LoadWrapper>
  )
}
export const FileInputLabel = styled.label<FileInputLabelProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1rem;
  border: ${(props) =>
    Object.values(UploadError).includes(props.status as UploadError)
      ? '0.125rem solid var(--veryLow-darker-color)'
      : Object.values(UploadSuccess).includes(props.status as UploadSuccess)
      ? '0.125rem solid var(--inRange-darker-color)'
      : props.active
      ? '0.125rem solid var(--brand-primary-12-color)'
      : '0.125rem solid var(--text-lightest)'};
  background-color: ${(props) =>
    props.active ? 'var(--brand-primary-4-color)' : 'var(--element-bg)'};
  color: ${(props) =>
    props.active ? 'var(--brand-primary-color)' : 'var(--text-primary)'};
  &:hover {
    background-color: var(--brand-primary-4-color);
    border: 0.125rem solid var(--brand-primary-12-color);
    color: var(--brand-primary-color);
  }
`
export const FileInputTitle = styled.div<FileInputTitleProps>`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  color: inherit;
`
export const DragInfoWrapper = styled.div`
  height: 5.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
export const DragInfoTitle = styled.div<FileInputTitleProps>`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: inherit;
`
export const DragInfoSubText = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: var(--text-lighter);
`
export const BrowseButton = styled.button`
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid var(--brand-primary-color);
  border-radius: 0.5rem;
  background-color: var(--white-color);
  color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
`
export const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

export const ErrorComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  color: var(--veryLow-darker-color);
  margin-top: 1rem;
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
`
export const SuccessComponent = styled(ErrorComponent)`
  color: var(--inRange-darker-color);
`
export const StatusLogo = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  font-weight: 900;
`
export const DownloadComponent = styled(FontAwesomeIcon)`
  font-size: 2rem;
  font-weight: 900;
  color: var(--inRange-color);
`
