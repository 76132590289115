import { SortingState } from '@tanstack/react-table'
import { ProviderObject } from '../../../containers/ClinicIntegrations/ClinicIntegrations'
import { Filters } from '../../UploadActivity/constants'

export enum PatientAccountLink {
  libreview = 'AbbottLibreViewWebCMIProvider',
  carelink = 'MedtronicCarelinkWebCMIProvider',
  dexcom = 'DexcomAPICMIProvider',
  ypsomed = 'ypsomed',
  fitbit = 'FitbitAPICMIProvider',
  notlinked = 'notlinked',
  notregistered = 'notregistered'
}

export enum ConsultationTime {
  within4hours = 'within4hours',
  today = 'today',
  within2days = 'within2days',
  within7days = 'within7days',
  within14days = 'within14days',
  within30days = 'within30days',
  past = 'past',
  notime = 'notime'
}
export enum LatestData {
  within2days = 'within2days',
  olderthan2days = 'olderthan2days',
  olderthan7days = 'olderthan7days',
  olderthan14days = 'olderthan14days',
  olderthan30days = 'olderthan30days',
  olderthan90days = 'olderthan90days',
  nodata = 'nodata'
}
export type ConsultationsParams = Filters & {
  consultationTime?: ConsultationTime
  latestData?: LatestData
  patientAccountLink?: PatientAccountLink
  importId: string
  sorting: SortingState
}

export enum ConsultationsFilter {
  consultationTime = 'consultationTime',
  latestData = 'latestData',
  patientAccountLink = 'patientAccountLink'
}

export interface AccountLinkProvider {
  provider: ProviderObject
  providerId: string
  providerPatientId: string
  patientId: string
  providerType: PatientAccountLink
}
export interface ConsultationsData {
  id: string
  consultationTime: string | null
  patientId: string | null
  patientName: string | null
  importedName: string | null
  importedMRN: string | null
  importId: string
  latestData: string | null
  providers: Array<AccountLinkProvider> | null
}
export interface ConsultationsAccountButtonProps {
  provider: AccountLinkProvider
  row: ConsultationsData
  inOptionsMenu: boolean
}

export enum ConsultationsImportErrorType {
  MissingRecord = 'MissingRecord',
  InvalidDate = 'InvalidDate',
  Other = 'Other',
  InvalidMRN = 'InvalidMRN',
  MissingName = 'MissingName'
}

export interface ConsultationsImportErrorSummaryObject {
  type: ConsultationsImportErrorType
  amount: number
}
