import styled from 'styled-components'

export const ListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
`

export const NotificationDot = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: var(--blue);
  position: absolute;
  left: -1rem;
`
export const ListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  height: 2rem;
`

export const ListHeaderText = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 2rem;
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  height: 21.5rem;
  align-items: flex-start;
`
export const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`

export const ListItemText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: inherit;
`

export const SelectAllListItemText = styled(ListItemText)`
  color: var(--text-lighter);
`
export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--element-bg);
  border: 0.0625rem solid var(--text-lightest);
  padding: 2rem 4rem;
  border-radius: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

export const FilterSection = styled.div`
  display: flex;
  gap: 4rem;
`

export const TopRight = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`
