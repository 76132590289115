import { flexRender, Table } from '@tanstack/react-table'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { FetchStatus } from '../../hooks/useFetch'
import { SortIndicator } from '../TableComponents/Sorting/DataTableSorting'
import { TableLayout } from '../TableComponents/TableLayout/TableLayout'
import {
  StickyTable,
  Th,
  ThWrapper
} from '../TableComponents/TableStyledElements/TableStyledElements'

const DataTableWrapper = styled(StickyTable)`
  tr {
    th:first-child,
    td:first-child {
      width: 6.5625rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 4.9375rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 8rem;
    }
  }
`

export function DataTable<T>({
  table,
  context = null,
  fetchStatus,
  fetchError
}: Readonly<{
  table: Table<T>
  context: ReactNode
  fetchStatus: FetchStatus
  fetchError?: Error | null
}>) {
  return (
    <TableLayout
      table={table}
      context={context}
      fetchStatus={fetchStatus}
      fetchError={fetchError}
      usesPagination={true}
      WrapperElement={DataTableWrapper}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                $options={header.column.id === 'options'}
                onClick={header.column.getToggleSortingHandler()}
              >
                <ThWrapper>
                  <span>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </span>
                  <SortIndicator
                    sorting={header.column.getIsSorted()}
                    canSort={header.column.getCanSort()}
                  />
                </ThWrapper>
              </Th>
            ))}
          </tr>
        ))}
      </thead>
    </TableLayout>
  )
}
