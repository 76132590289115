import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSiteSettingsContext } from '../../../../core/contexts/site-settings-context'
import { FetchStatus } from '../../../../hooks/useFetch'
import { TableLayout } from '../../../TableComponents/TableLayout/TableLayout'
import {
  StickyTable,
  Th,
  ThWrapper
} from '../../../TableComponents/TableStyledElements/TableStyledElements'
import { ConsultationsImportErrorSummaryObject } from '../../constants/constants'
import { ConsultationsSummaryData } from '../ConsultationsSummary'

const TableWrapper = styled(StickyTable)`
  tr {
    th:nth-child(2),
    td:nth-child(2) {
      width: 21rem;
    }
  }
`
enum ConsultationsImportErrorType {
  MissingRecord = 'MissingRecord',
  InvalidDate = 'InvalidDate',
  Other = 'Other'
}
interface ErrorSummaryObject {
  type: ConsultationsImportErrorType
  amount: number
}

interface ConsultationsSummaryErrorTableProps {
  summary: undefined | null | ConsultationsSummaryData
}
export const ConsultationsSummaryErrorTable = ({
  summary
}: ConsultationsSummaryErrorTableProps) => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()

  const columns: ColumnDef<ErrorSummaryObject>[] = useMemo(
    () => [
      {
        id: 'count',
        header: t('consultations_summary_error_table_title_1'),
        cell: ({ row }: { row: Row<ErrorSummaryObject> }) => row.original.amount
      },
      {
        id: 'error_type',
        header: t('consultations_summary_error_table_title_2'),
        cell: ({ row }: { row: Row<ErrorSummaryObject> }) =>
          t(
            `${
              row.original.type.toLowerCase() === 'invalidmrn'
                ? (siteSettings?.mrnType ?? 'dev') + '.'
                : ''
            }${row.original.type.toLowerCase()}.error_description`
          )
      }
    ],
    [siteSettings?.mrnType, t]
  )

  const table = useReactTable({
    data: summary
      ? JSON.parse(window.atob(summary.errorSummary)).filter(
          (summaryItem: ConsultationsImportErrorSummaryObject) =>
            summaryItem.amount !== 0
        )
      : [],
    columns,
    rowCount: summary
      ? JSON.parse(window.atob(summary.errorSummary)).length
      : 0,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: () => ({}),
    getRowId: (originalRow) => originalRow.type
  })

  return summary && summary.rowsWithErrors > 0 ? (
    <TableLayout
      table={table}
      context={null}
      fetchStatus={FetchStatus.Success}
      fetchError={null}
      usesPagination={false}
      WrapperElement={TableWrapper}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th key={header.id} $options={false}>
                <ThWrapper>
                  <span>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </span>
                </ThWrapper>
              </Th>
            ))}
          </tr>
        ))}
      </thead>
    </TableLayout>
  ) : null
}
