import { t } from 'i18next'
import { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import {
  BasalInjectionInfo,
  DoseRecommendationValue,
  getDoseRecommendationsResponse,
  NovoPenObject
} from '../../core/api/getDoseRecommendations'
import { putNewDoseRecommendation } from '../../core/api/putNewDoseRecommendation'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { formatNumber } from '../../helpers/helpers'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { Modal } from '../Modal/Modal'
import { WarningBox, WarningType } from '../WarningComponent/WarningBox'
import { CurrentDose } from './CurrentDose/CurrentDose'
import { DoseRecommender } from './DoseRecommender/DoseRecommender'
import { GracePeriodModal } from './GracePeriodModal/GracePeriodModal'
import { PenSection } from './PenSection/PenSection'

const Wrapper = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 1.5rem;
  width: 100%;
  box-sizing: border-box;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  height: 14.75rem;
`
const Footer = styled.div`
  display: flex;
  width: 100%;
`

const Button = styled.button`
  min-width: 26.25rem;
  max-width: 30rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--blue);
  color: var(--white-color);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: var(--blue-darker);
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      background-color: var(--blue);
    }
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
`
export const PenDosing = ({
  smartPen,
  doseRecommendation,
  basalInjectionInfo,
  isPatient,
  updateHistory,
  setDoseRecommendation
}: {
  smartPen: NovoPenObject | null
  doseRecommendation: DoseRecommendationValue
  basalInjectionInfo: BasalInjectionInfo
  isPatient: boolean
  updateHistory: () => void
  setDoseRecommendation: Dispatch<
    SetStateAction<getDoseRecommendationsResponse | null>
  >
}) => {
  const [showGracePeriodModal, setShowGracePeriodModal] = useState(false)
  const { siteSettings } = useSiteSettingsContext()
  const { patient } = usePatientContext()
  const [nextDose, setNextDose] = useState<number>(
    doseRecommendation.recommendedDose
  )

  const doseChange = doseRecommendation.currentDose
    ? Math.abs(nextDose - doseRecommendation.currentDose) /
      doseRecommendation.currentDose
    : 0

  const largeChange =
    doseChange > 0.2 && doseRecommendation.currentDose !== null

  const handleSetNewDoseRecommendation = async () => {
    try {
      await putNewDoseRecommendation(
        patient?.patientId ?? '',
        nextDose,
        siteSettings?.releaseVersion
      )
      setDoseRecommendation(null)
      updateHistory()
    } catch (e) {
      SnackBar({
        type: SnackBarType.Error,
        message: t(
          'Something went wrong when setting the new dose recommendation, please try again'
        )
      })
    }
  }

  return (
    <>
      {showGracePeriodModal && (
        <Modal isOpen={showGracePeriodModal}>
          <GracePeriodModal
            closeFun={() => setShowGracePeriodModal(false)}
            nextDose={nextDose}
            handleSetDose={() => {
              setShowGracePeriodModal(false)
              handleSetNewDoseRecommendation()
            }}
          ></GracePeriodModal>
        </Modal>
      )}
      <Wrapper data-testid="penDosingComponent">
        <>
          <Row>
            <PenSection
              smartPen={smartPen}
              isPatient={isPatient}
              basalInjectionInfo={basalInjectionInfo}
            />
            <CurrentDose
              isPatient={isPatient}
              hcpName={doseRecommendation.setBy}
              time={doseRecommendation.currentDoseTimeZone}
              currentDose={doseRecommendation.currentDose}
            />
            {!isPatient && (
              <Column>
                <DoseRecommender
                  originalNextDose={doseRecommendation.recommendedDose}
                  previousDose={doseRecommendation.currentDose}
                  nextDose={nextDose}
                  setNextDose={setNextDose}
                  largeChange={largeChange}
                  doseState={doseRecommendation.state}
                />
                <Button
                  data-testid="setNewDose"
                  onClick={
                    doseRecommendation.state.cooldown
                      ? () => setShowGracePeriodModal(true)
                      : handleSetNewDoseRecommendation
                  }
                  disabled={!!(nextDose < 0 || nextDose > 1000)}
                >
                  {t('prescribe_new_dose', { newDose: nextDose })}
                </Button>
              </Column>
            )}
          </Row>
          {!isPatient && largeChange && (
            <Footer>
              <WarningBox
                text={t('high_dose_warning', {
                  percentage: formatNumber({
                    value: doseChange * 100,
                    decimals: 0
                  })
                })}
                warningType={WarningType.PenTitration}
              ></WarningBox>
            </Footer>
          )}
        </>
      </Wrapper>
    </>
  )
}
