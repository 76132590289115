import { faFileCsv } from '@fortawesome/pro-solid-svg-icons'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalComponent } from '../../Modal/Modal'
import {
  FilterFunctionButton,
  FilterFunctionButtonTheme
} from '../../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { ConsultationsSummaryData } from '../ConsultationsSummary/ConsultationsSummary'
import { ImportConsultationsModal } from './ImportConsultationsModal/ImportConsultationsModal'

interface ImportConsultationsProps {
  setSummary: Dispatch<SetStateAction<ConsultationsSummaryData | undefined>>
  setRefreshSummary: Dispatch<SetStateAction<boolean>>
}
export const ImportConsultations = ({
  setSummary,
  setRefreshSummary
}: ImportConsultationsProps) => {
  const { t } = useTranslation()
  const [showImportModal, setShowImportModal] = useState(false)
  return (
    <>
      <Modal isOpen={showImportModal}>
        <ModalComponent
          closeFun={() => setShowImportModal(false)}
          closetestid={'closeImportModal'}
        >
          <ImportConsultationsModal
            setSummary={setSummary}
            setRefreshSummary={setRefreshSummary}
          />
        </ModalComponent>
      </Modal>
      <FilterFunctionButton
        handleClick={() => setShowImportModal(true)}
        icon={faFileCsv}
        text={t('import_consultations_button_text')}
        theme={FilterFunctionButtonTheme.blue}
        datatestid="showImportConsultationsModalButton"
      />
    </>
  )
}
