import { faArrowDownToBracket } from '@fortawesome/pro-solid-svg-icons'
import saveAs from 'file-saver'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSiteSettingsContext } from '../../../../core/contexts/site-settings-context'
import {
  Button,
  ButtonIcon,
  ButtonText,
  ConsultationsSummaryData
} from '../../ConsultationsSummary/ConsultationsSummary'
import { ImportConsultationsFileDrop } from './ImportConsultationsFileDrop/ImportConsultationsFileDrop'

const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
  box-sizing: border-box;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 2.25rem;
`
const Body = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  align-self: stretch;
`

interface ImportConsultationsModalProps {
  setSummary: Dispatch<SetStateAction<ConsultationsSummaryData | undefined>>
  setRefreshSummary: Dispatch<SetStateAction<boolean>>
}
export const ImportConsultationsModal = ({
  setSummary,
  setRefreshSummary
}: ImportConsultationsModalProps) => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  function downloadTemplateCSV() {
    const csv = '0123456789,YYYYMMDD HH:mm,Test Name'
    const blob = new Blob(
      [decodeURIComponent('%ef%bb%bf') /* prepend bom */, csv],
      { type: 'text/csv;charset=utf-8' }
    )

    saveAs(blob, 'template.csv')
  }
  return (
    <Wrapper data-testid="importConsultationsModal">
      <TitleWrapper>
        <Title>{t('import_consultations_modal_title')}</Title>
      </TitleWrapper>
      <Body>
        {t('import_consultations_modal_body', {
          deploymentName: siteSettings?.deploymentName
        })}
      </Body>
      <Button
        onClick={downloadTemplateCSV}
        data-testid="downloadConsultationsImportTemplateCSV"
      >
        <ButtonIcon icon={faArrowDownToBracket} />
        <ButtonText>{t('download_template_consultation_csv')}</ButtonText>
      </Button>
      <ImportConsultationsFileDrop
        setSummary={setSummary}
        setRefreshSummary={setRefreshSummary}
      />
    </Wrapper>
  )
}
