import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faCircleQuestion,
  faQuestion,
  faXmark
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { InjectionStatus } from '../../../../../core/api/getDoseRecommendations'
import { RecentDayInjectionItemWrapper } from './RecentDayInjectionItemWrapper/RecentDayinjectionItemWrapper'

const ItemIcon = styled(FontAwesomeIcon)<{ $iconColor: string }>`
  color: ${(props) => props.$iconColor};
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
`

export const RecentDayInjectionItem = ({
  injectionStatus,
  daysAgo,
  total
}: {
  injectionStatus: InjectionStatus
  daysAgo: number
  total: number | null
}) => {
  let bgColor = ''
  let hoverBgColor = ''
  let icon: IconProp = faCircleQuestion
  let iconColor = ''

  switch (injectionStatus) {
    case InjectionStatus.unknown:
      bgColor = 'var(--text-lighter)'
      hoverBgColor = 'var(--text-medium)'
      icon = faQuestion
      iconColor = 'var(--white-color)'
      break
    case InjectionStatus.missed:
      bgColor = 'var(--destructive-12)'
      hoverBgColor = 'var(--destructive-24)'
      icon = faXmark
      iconColor = 'var(--destructive)'
      break
    case InjectionStatus.injected:
      bgColor = 'var(--blue-12)'
      hoverBgColor = 'var(--blue-24)'
      icon = faCheck
      iconColor = 'var(--blue)'
  }
  return (
    <RecentDayInjectionItemWrapper
      bgColor={bgColor}
      hoverBgColor={hoverBgColor}
      daysAgo={daysAgo}
      total={total}
    >
      <ItemIcon icon={icon} $iconColor={iconColor}></ItemIcon>
    </RecentDayInjectionItemWrapper>
  )
}
