import {
  faArrowUpFromArc,
  faHouseMedical
} from '@fortawesome/pro-regular-svg-icons'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Clinic } from '../../../core/entities/clinic.entity'
import { Patient } from '../../../core/entities/patient.entity'
import { Capabilities } from '../../../core/entities/siteSettings.entity'
import { User } from '../../../core/entities/user.entity'
import { useCapabilities } from '../../../hooks/use-capabilities'
import { Header } from '../../Header/Header'
import { HeaderButton } from '../../HeaderButton/HeaderButton'
import { UploaderButton } from '../../HeaderButton/UploaderButton'
import { getLeftContent } from '../HCPLeftcontent'

interface ComponentProps {
  patient: Patient | null
  clinic: Clinic | null
  user: User | null
  onLogout: () => void
  viewedPatientIsBlinded: boolean
  setViewedPatientIsBlinded: Dispatch<SetStateAction<boolean>>
}

// TODO This header shouldn't be aware of the path it is in.
// That decision is responsibility of the layout that is rendering this element.
export const HCPHeader = ({
  patient,
  clinic,
  user,
  onLogout,
  viewedPatientIsBlinded,
  setViewedPatientIsBlinded
}: ComponentProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { isCapabilitySupportedBySite } = useCapabilities()
  const uploaderIsActive = location.pathname.includes('uploader')
  return (
    <Header
      user={user}
      logout={onLogout}
      leftContent={getLeftContent({
        location,
        patient,
        clinic,
        viewedPatientIsBlinded,
        setViewedPatientIsBlinded
      })}
      rightContent={
        <>
          <HeaderButton
            inClinic={location.pathname.includes('clinic')}
            data-testid="clinicButton"
            onClick={() => navigate('/portal/clinic')}
            icon={faHouseMedical}
          >
            {t('Clinic')}
          </HeaderButton>
          {isCapabilitySupportedBySite(Capabilities.uploaderInPortal) && (
            <UploaderButton
              inUploader={uploaderIsActive}
              icon={faArrowUpFromArc}
              onClick={() => navigate('/portal/uploader')}
            >
              {t('Upload')}
            </UploaderButton>
          )}
        </>
      }
    />
  )
}
