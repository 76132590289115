import { TFunction } from 'react-i18next'
import styled from 'styled-components'
import { Td, Tr, TText } from '../../../components/Table/ScrollTableComponent'
import { getChangeString } from '../../../components/TitrationHistory/TitrationHistory'
import { formatNumber } from '../../../helpers/helpers'
import { PatientPenTitrationEvent } from '../PenTitrations'
import { TimeInRangeComponent } from './TimeInRangeComponent/TimeInRangeComponent'

const RowBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
const TdClickable = styled(Td)`
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg);
  }
`
const InRangeWrapper = styled(RowBoxWrapper)`
  width: 4rem;
  justify-content: flex-end;
`
const SubText = styled(TText)`
  color: var(--text-lighter);
`

const LinkText = styled(TText)`
  &:hover {
    cursor: pointer;
    color: var(--brand-primary-color);
  }
`

const ParentText = styled(TText)`
  ${TdClickable}:hover & {
    color: var(--brand-darker-color);
  }
`

export const PenTitrationRow = ({
  event,
  linkToPatient,
  t
}: {
  event: PatientPenTitrationEvent
  linkToPatient: (mrn: string, penDosing: boolean) => Promise<void>
  t: TFunction<'translation', undefined>
}) => {
  return (
    <Tr
      key={'titrationHistoryEvent' + event.patientName + event.mrn}
      data-testid="penTitrationRow"
    >
      <Td>
        <RowBoxWrapper>
          <LinkText
            onClick={() => linkToPatient(event.mrn, false)}
            data-testid="penTitrationName"
          >
            {event.patientName}
          </LinkText>
        </RowBoxWrapper>
      </Td>
      <Td>
        <RowBoxWrapper>
          <LinkText
            onClick={() => linkToPatient(event.mrn, false)}
            data-testid="penTitrationMrn"
          >
            {event.mrn.substring(0, 6) + '-' + event.mrn.substring(6, 11)}
          </LinkText>
        </RowBoxWrapper>
      </Td>
      <Td>
        <RowBoxWrapper>
          <TimeInRangeComponent data={event.timeInRange} />
          <InRangeWrapper>
            <TText>
              {event.timeInRange !== null
                ? formatNumber({
                    value: event.timeInRange.range3.percentage,
                    decimals: 0
                  })
                : '--'}
            </TText>
            <SubText>%</SubText>
          </InRangeWrapper>
        </RowBoxWrapper>
      </Td>
      <Td>
        <RowBoxWrapper>
          <TText data-testid="sensorUsageValue">
            {formatNumber({ value: event.sensorCoverage, decimals: 0 })}
          </TText>
          <SubText>%</SubText>
        </RowBoxWrapper>
      </Td>
      <TdClickable
        data-testid="currentDoseWrapper"
        onClick={() => linkToPatient(event.mrn, true)}
      >
        <RowBoxWrapper>
          <ParentText data-testid="currentDoseValue">
            {event.currentDose ?? '--'}
          </ParentText>
          <SubText>U</SubText>
        </RowBoxWrapper>
      </TdClickable>
      <TdClickable
        data-testid="doseChangeWrapper"
        onClick={() => linkToPatient(event.mrn, true)}
      >
        <RowBoxWrapper>
          {getChangeString(event.change, event.timeSinceLastTitration, t)}
        </RowBoxWrapper>
      </TdClickable>
      <TdClickable
        data-testid="suggestedDoseWrapper"
        onClick={() => linkToPatient(event.mrn, true)}
      >
        <RowBoxWrapper>
          <ParentText data-testid="suggestedDoseValue">
            {event.suggestedDose ?? '--'}
          </ParentText>
          <SubText>U</SubText>
        </RowBoxWrapper>
      </TdClickable>
      <TdClickable
        data-testid="timeSinceLastTitrationWrapper"
        onClick={() => linkToPatient(event.mrn, true)}
      >
        <RowBoxWrapper>
          <TText data-testid="timeSinceLastTitrationValue">
            {event.timeSinceLastTitration ?? '--'}
          </TText>
          <SubText>{t('days')}</SubText>
        </RowBoxWrapper>
      </TdClickable>
    </Tr>
  )
}
