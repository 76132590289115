import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import GreyPenDeviceId from '../../../assets/Pens/NovoPenGreyDeviceId.png'
import { ModalComponent } from '../../Modal/Modal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.0625rem solid var(--element-bg);
  background-color: var(--white-color);
  border-radius: 1.5rem;
  width: 31.25rem;
  height: 25.875rem;
  padding: 2rem;
  box-sizing: border-box;
  gap: 2rem;
  position: relative;
  overflow: hidden;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 2.25rem;
`
const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-lighter);
`

const PenIMG = styled.img`
  width: 29.4375rem;
  height: 52.375rem;
  position: absolute;
  right: -9.25rem;
  top: 7rem;
`

const DeviceIdIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 7.625rem;
  gap: 0.375rem;
`
const DeviceIdIndicatorText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-primary);
`

const DeviceIdIndicatorLine = styled.div`
  width: 13.25rem;
  height: 0.125rem;
  background-color: var(--brand-primary-color);
`
export const FindDeviceId = ({ closeFun }: { closeFun: () => void }) => {
  const { t } = useTranslation()
  return (
    <ModalComponent closeFun={closeFun} closetestid="closeFindDeviceId">
      <Wrapper data-testid="findDeviceId">
        <Title>{t('find_novo_pen_id_title')}</Title>
        <Text>{t('find_novo_pen_id_body')}</Text>
        <DeviceIdIndicator>
          <DeviceIdIndicatorText>
            {t('find_novo_pen_id_indicator')}
          </DeviceIdIndicatorText>
          <DeviceIdIndicatorLine></DeviceIdIndicatorLine>
        </DeviceIdIndicator>
        <PenIMG src={GreyPenDeviceId}></PenIMG>
      </Wrapper>
    </ModalComponent>
  )
}
