import { faSortDown, faSortUp, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { DeleteModal } from '../../../../components/DeleteModal/DeleteModal'
import { Modal } from '../../../../components/Modal/Modal'
import {
  HeaderWrapper,
  SortEnum,
  SortIconWrapper
} from '../../../../components/ProviderPatients/ProviderPatients'
import { spinnerSize } from '../../../../components/Spinner/Spinner'
import { ErrorRow } from '../../../../components/Table/ErrorRow'
import { NoDataRow } from '../../../../components/Table/NoDataRow'
import {
  THead,
  TText,
  Td,
  Th,
  Tr
} from '../../../../components/Table/ScrollTableComponent'
import { ScrollTableLoading } from '../../../../components/Table/ScrollTableLoading.tsx'
import {
  SortIconDown,
  SortIconUp
} from '../../../../components/Uploads/UploadsHeaderComponent/UploadsHeaderComponent'
import { getPatient } from '../../../../core/api/get-patient-api'
import { removePatientFromPopulation } from '../../../../core/api/remove-patient-from-population'
import { useSessionContext } from '../../../../core/contexts/session-context'
import { useSiteSettingsContext } from '../../../../core/contexts/site-settings-context'
import { UserType } from '../../../../core/entities/user.entity'
import { PatientPaginationValue } from '../interfaces'

const Tdd = styled(Td)`
  line-height: 2.25rem;
  background-color: inherit;
`

const Text = styled.span`
  cursor: pointer;
  &:hover {
    color: var(--brand-primary-color);
  }
`
const Trr = styled(Tr)<{ $populationId: string | undefined }>`
  position: relative;
  &:hover {
    background-color: ${(props) =>
      props.$populationId !== undefined && 'var(--element-bg)'};
  }
`

const RemovePatientWrapper = styled(Tdd)`
  padding: 0.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 0.0625rem solid var(--text-lightest);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background-color: var(--white-color);
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--text-medium);
  &:hover {
    background-color: var(--destructive);
    color: var(--white-color);
    border: 0.0625rem solid var(--destructive);
  }
`
const RemovePatient = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
`
export const PatientGroup = () => {
  return (
    <colgroup data-testid="patientsGroup">
      <col style={{ width: '27.5%' }}></col>
      <col style={{ width: '12.5%' }}></col>
      <col style={{ width: '22.5%' }}></col>
      <col style={{ width: '37.5%' }}></col>
    </colgroup>
  )
}

interface PatientHeaderProps {
  sorting: SortEnum
  setSorting: Dispatch<SetStateAction<SortEnum>>
}
export const PatientHeader = ({ sorting, setSorting }: PatientHeaderProps) => {
  const { siteSettings } = useSiteSettingsContext()
  return (
    <THead data-testid="patientsHeader">
      <Th>
        <HeaderWrapper>
          <TText>{t('Name')}</TText>
          <SortIconWrapper
            data-testid="sortbutton"
            onClick={() =>
              setSorting(
                sorting === SortEnum.Ascending
                  ? SortEnum.Descending
                  : SortEnum.Ascending
              )
            }
          >
            <SortIconUp
              data-testid="sortUp"
              icon={faSortUp}
              $active={sorting === SortEnum.Ascending}
            />
            <SortIconDown
              data-testid="sortDown"
              icon={faSortDown}
              $active={sorting === SortEnum.Descending}
            />
          </SortIconWrapper>
        </HeaderWrapper>
      </Th>
      <Th>
        <HeaderWrapper>
          <TText>{t(`${siteSettings?.mrnType}.mrn-title`)}</TText>
        </HeaderWrapper>
      </Th>
      <Th>
        <HeaderWrapper>
          <TText>{t('Population')}</TText>
        </HeaderWrapper>
      </Th>
      <Th>
        <HeaderWrapper>
          <TText>{t('Sub-population')}</TText>
        </HeaderWrapper>
      </Th>
    </THead>
  )
}
const PatientBodyRow = ({
  patient,
  populationId,
  refresh
}: {
  patient: PatientPaginationValue
  populationId: string | undefined
  refresh: () => void
}) => {
  const [hover, setHover] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { user } = useSessionContext()
  const navigate = useNavigate()
  const linkToPatient = async () => {
    try {
      const response = (
        await getPatient(patient.mrn.replace(/-/g, ''))
      ).data.pop()
      if (!response) {
        throw new Error(t('Patient not found'))
      }
      navigate(`/portal/patients/${patient.patientId}/insights`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Trr
      key={patient.patientId}
      data-testid="patientsBodyRow"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      $populationId={populationId}
    >
      <Tdd>
        <Text onClick={linkToPatient}>{patient.name}</Text>
      </Tdd>
      <Tdd>
        <Text onClick={linkToPatient}>
          {patient.mrn.substring(0, 6) + '-' + patient.mrn.substring(6, 11)}
        </Text>
      </Tdd>

      <Tdd>{patient.populationName ?? ''}</Tdd>
      <Tdd>{patient.subpopulation?.name ?? ''}</Tdd>
      {populationId && user?.type === UserType.HCPAdmin && (
        <>
          {hover && (
            <RemovePatientWrapper
              data-testid="removePatientButton"
              onClick={() => setShowModal(true)}
            >
              <RemovePatient icon={faTrash} />
            </RemovePatientWrapper>
          )}
          <Modal isOpen={showModal}>
            <DeleteModal
              title={t('removePatientFromPopulation', {
                patientName: patient.name,
                populationName: patient.populationName
              })}
              body={t(
                'This action can not be undone. It may affect what features the patient has access to, as well as remove any blinding of data that the patient is subject to.'
              )}
              buttonText={t('Remove patient')}
              acceptfn={async () => {
                await removePatientFromPopulation(
                  populationId,
                  patient.patientId
                )
                setShowModal(false)
                refresh()
              }}
              cancelfn={() => setShowModal(false)}
            ></DeleteModal>
          </Modal>
        </>
      )}
    </Trr>
  )
}
export const PatientBody = ({
  patients,
  error,
  populationId,
  refresh
}: {
  patients: PatientPaginationValue[] | null
  error: Error | null
  populationId: string | undefined
  refresh: () => void
}) => {
  const getBody = () => {
    if (error) {
      return <ErrorRow />
    }
    if (patients) {
      if (patients.length === 0) {
        return <NoDataRow />
      } else {
        return patients.map((patient) => (
          <PatientBodyRow
            key={patient.mrn}
            patient={patient}
            populationId={populationId}
            refresh={refresh}
          />
        ))
      }
    } else {
      return <ScrollTableLoading size={spinnerSize.large} />
    }
  }
  return <>{getBody()}</>
}
