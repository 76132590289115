import {
  faAddressCard as faAddressCardRegular,
  faArrowUpFromBracket as faArrowUpFromBracketRegular,
  faFileExport as faFileExportRegular,
  faHistory as faHistoryRegular,
  faLink as faLinkRegular
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAddressCard as faAddressCardSolid,
  faArrowUpFromBracket as faArrowUpFromBracketSolid,
  faFileExport as faFileExportSolid,
  faHistory as faHistorySolid,
  faLink as faLinkSolid
} from '@fortawesome/pro-solid-svg-icons'

import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { SideMenuComponent } from '../../components/SideMenuComponent/SideMenuComponent'
import { usePatientViewsContext } from '../../core/contexts/patient-view-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'

const MoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  min-width: 1200px;
`

const PageWrapper = styled.div`
  max-width: 1500px;
  width: 100%;
`

export const More = () => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const { patientViews } = usePatientViewsContext()
  const menuData = [
    {
      icon: {
        regular: faLinkRegular,
        solid: faLinkSolid
      },
      alias: t('Integrations'),
      info: '',
      to: 'integrations',
      show: true
    },
    {
      icon: {
        regular: faArrowUpFromBracketRegular,
        solid: faArrowUpFromBracketSolid
      },
      alias: t('Data Upload'),
      info: '',
      to: 'data-upload',
      show: true
    },
    {
      icon: { regular: faFileExportRegular, solid: faFileExportSolid },
      alias: t('Data Export'),
      info: '',
      to: 'data-export',
      show: true
    },
    {
      icon: { regular: faHistoryRegular, solid: faHistorySolid },
      alias: t('History'),
      info: '',
      to: 'history',
      show: true
    },
    {
      icon: { regular: faAddressCardRegular, solid: faAddressCardSolid },
      alias: user?.type === UserType.Patient ? t('My Info') : t('Patient Info'),
      info: '',
      to: 'info',
      show: true
    }
  ]

  return (
    <MoreWrapper>
      <SideMenuComponent items={menuData}></SideMenuComponent>
      <PageWrapper>
        <Outlet />
      </PageWrapper>
    </MoreWrapper>
  )
}
