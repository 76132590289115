import { useMemo, useState } from 'react'

export interface UseFilterHook {
  selected: string[] | string
  allSelected: boolean
  data: string[]
  toggle: (value: string) => void
  deselectAll: () => void
  selectAll: () => void
  toggleAll: () => void
  reset: () => void
}

export function useDataFilter(
  data: string[],
  initialState: string[] | string,
  currentState: string[] | string
): UseFilterHook {
  const [selected, setSelected] = useState(currentState)

  const allSelected = useMemo(() => {
    return selected.length === data.length
  }, [selected, data])

  const toggle = (value: string) => {
    setSelected((values) => {
      if (Array.isArray(values)) {
        return values.includes(value)
          ? values.filter((v) => v !== value)
          : [...values, value]
      } else return value
    })
  }

  const deselectAll = () => {
    setSelected([])
  }

  const selectAll = () => {
    setSelected(data)
  }

  const toggleAll = () => {
    if (allSelected) {
      deselectAll()
    } else {
      selectAll()
    }
  }

  const reset = () => {
    setSelected(initialState)
  }

  return {
    selected,
    allSelected,
    data,
    toggle,
    toggleAll,
    deselectAll,
    selectAll,
    reset
  }
}
