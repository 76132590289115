import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'

const Wrapper = styled.div<{ $isPatient: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  align-items: ${(props) => (props.$isPatient ? 'flex-start' : 'center')};
  justify-content: center;
  padding: ${(props) => (props.$isPatient ? '0rem 2rem' : '1.5rem')};
  gap: 0.5rem;
  background-color: var(--element-bg);
  box-sizing: border-box;
  width: ${(props) => (props.$isPatient ? '50%' : '26.75rem')};
  height: 100%;
`
const Title = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 600;
  line-height: 1.5rem;
  white-space: nowrap;
`
const PatientTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 2.25rem;
`
const ValueUnitWrapper = styled.div<{ $isPatient: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 0.625rem;
  padding: ${(props) => (props.$isPatient ? '0' : '0.75rem 1rem')};
`
const Value = styled.span`
  font-family: inter;
  font-size: 2.625rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 3rem;
`
const Unit = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-lighter);
  font-weight: 700;
  line-height: 2.25rem;
  vertical-align: bottom;
`
const Text = styled(Title)`
  color: var(--text-lighter);
`

const HcpFooterText = styled(Text)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
`
const TextValue = styled(Title)`
  font-size: 1rem;
  line-height: 1.5rem;
`
const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
const TextValueLight = styled(TextValue)`
  color: var(--text-lighter);
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`
const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const NoPrescribedDose = styled.span`
  color: var(--text-medium);
  text-align: center;
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`
const NoRecommendation = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const NoRecommendationTitle = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-medium);
`
const NoRecommendationBody = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-lighter);
`
interface CurrentDoseProps {
  isPatient: boolean
  hcpName: string | null
  time: string | null
  currentDose: number | null
}
export const CurrentDose = ({
  isPatient,
  hcpName,
  time,
  currentDose
}: CurrentDoseProps) => {
  const { t } = useTranslation()
  const getTitle = () => {
    if (isPatient) {
      return <PatientTitle>{t('prescribed_basal_dose')}</PatientTitle>
    } else {
      return <Title>{t('current_prescribed_dose')}</Title>
    }
  }
  const getFooter = () => {
    if (isPatient) {
      return (
        <Footer>
          <FooterSection>
            <Text>{t('Prescribed by')}</Text>
            <TextValue>{hcpName}</TextValue>
          </FooterSection>
          <FooterSection>
            <Text>{t('Prescribed on')}</Text>
            <TextRow>
              <TextValue> {dayjs(time).format('DD/MM/YYYY')}</TextValue>
              <TextValueLight> @ </TextValueLight>
              <TextValue> {dayjs(time).format('HH:mm')} </TextValue>
            </TextRow>
          </FooterSection>
        </Footer>
      )
    } else {
      return (
        <HcpFooterText>
          {t('Prescribed on')} {dayjs(time).format('DD/MM/YYYY')}
        </HcpFooterText>
      )
    }
  }
  return (
    <Wrapper $isPatient={isPatient}>
      {isPatient && hcpName === null ? (
        <NoRecommendation>
          <NoRecommendationTitle>
            {t('No recommendation set')}
          </NoRecommendationTitle>
          <NoRecommendationBody>
            {t(
              'Once a recommended basal dose has been prescribed by your care provider it will show here.'
            )}
          </NoRecommendationBody>
        </NoRecommendation>
      ) : time !== null ? (
        <>
          {getTitle()}

          <ValueUnitWrapper $isPatient={isPatient}>
            <Value>{currentDose}</Value>
            <Unit>U</Unit>
          </ValueUnitWrapper>
          {getFooter()}
        </>
      ) : (
        <NoPrescribedDose>{t('no_prescribed_dose')}</NoPrescribedDose>
      )}
    </Wrapper>
  )
}
