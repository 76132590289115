import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { UseFilterHook } from '../../../../hooks/useDataFilter'
import {
  CheckBox,
  CheckBoxSize,
  CheckBoxState
} from '../../../CheckBox/CheckBox'
import {
  RadioButton,
  RadioButtonSize,
  RadioButtonState
} from '../../../RadioButton/RadioButton'
import { FilterFunctionButton } from '../FilterFunctionButton/FilterFunctionButton'
import {
  List,
  ListHeader,
  ListHeaderText,
  ListItem,
  ListItemText,
  ListWrapper,
  NotificationDot,
  SelectAllListItemText
} from '../FilterStyledElements/FilterStyledElements'

export enum DataFilterType {
  singleSelect,
  multiSelect
}
export function DataFilter({
  title,
  filter,
  slug,
  type
}: Readonly<{
  title: string
  filter: UseFilterHook
  slug: string
  type: DataFilterType
}>) {
  const { t } = useTranslation()

  return (
    <ListWrapper>
      <ListHeader>
        {filter.selected.length > 0 ? <NotificationDot /> : null}
        <ListHeaderText>{title}</ListHeaderText>
        <FilterFunctionButton
          icon={faRotateRight}
          handleClick={() => filter.reset()}
        />
      </ListHeader>
      <List>
        {type === DataFilterType.multiSelect && (
          <ListItem key="all" onClick={() => filter.toggleAll()}>
            <CheckBox
              state={
                filter.allSelected
                  ? CheckBoxState.check
                  : CheckBoxState.unchecked
              }
              size={CheckBoxSize.medium}
            />
            <SelectAllListItemText>
              {t('filter.selection-count', { count: filter.selected.length })}
            </SelectAllListItemText>
          </ListItem>
        )}
        {type === DataFilterType.singleSelect && (
          <ListItem key="all" onClick={() => filter.toggle('')}>
            <RadioButton
              state={
                filter.selected === ''
                  ? RadioButtonState.check
                  : RadioButtonState.unchecked
              }
              size={RadioButtonSize.medium}
            />
            <SelectAllListItemText>
              {t('single_filter_all')}
            </SelectAllListItemText>
          </ListItem>
        )}
        {filter.data.map((value) => (
          <ListItem key={value} onClick={() => filter.toggle(value)}>
            {type === DataFilterType.multiSelect ? (
              <CheckBox
                state={
                  filter.selected?.includes(value)
                    ? CheckBoxState.check
                    : CheckBoxState.unchecked
                }
                size={CheckBoxSize.medium}
              />
            ) : (
              <RadioButton
                state={
                  filter.selected?.includes(value)
                    ? RadioButtonState.check
                    : RadioButtonState.unchecked
                }
                size={RadioButtonSize.medium}
              ></RadioButton>
            )}
            <ListItemText>
              {t(
                `data-filter.${slug.toLowerCase()}.${value.toLowerCase()}`,
                value
              )}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  )
}
