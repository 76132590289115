import styled from 'styled-components'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'

const Wrapper = styled.div`
  padding: 4rem;
`

export function DataTableLoading() {
  return (
    <Wrapper>
      <Spinner spinnersize={spinnerSize.medium} />
    </Wrapper>
  )
}
