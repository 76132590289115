interface formatNumberProps {
  value: number
  decimals: number
}
export const formatNumber = ({ value, decimals }: formatNumberProps) => {
  return new Intl.NumberFormat('da-DK', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value)
}

export function formatCPR(mrn: string) {
  if (!mrn || (mrn && mrn.length !== 10)) return mrn
  return mrn.substring(0, 6) + '-' + mrn.substring(6, 11)
}
