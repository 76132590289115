import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDataFilter } from '../../../hooks/useDataFilter'
import {
  DataFilter,
  DataFilterType
} from '../../TableComponents/Filters/DataFilter/DataFilter'
import { FilterFunctionButton } from '../../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import {
  FilterSection,
  FiltersWrapper,
  TopRight
} from '../../TableComponents/Filters/FilterStyledElements/FilterStyledElements'
import {
  ConsultationsFilter,
  ConsultationTime,
  LatestData,
  PatientAccountLink
} from '../constants/constants'

export function ConsultationsFilters({
  defaults,
  currentFilters,
  onChange
}: {
  defaults: { [key: string]: string[] | string }
  currentFilters: { [key: string]: string[] | string }
  onChange: (values: { [key: string]: string[] | string }) => void
}) {
  const { t } = useTranslation()
  const isFirstRender = useRef(true)

  const patientAccountLinkFilter = useDataFilter(
    Object.values(PatientAccountLink),
    defaults[ConsultationsFilter.patientAccountLink],
    currentFilters[ConsultationsFilter.patientAccountLink]
  )

  const consultationTimeFilter = useDataFilter(
    Object.values(ConsultationTime),
    defaults[ConsultationsFilter.consultationTime],
    currentFilters[ConsultationsFilter.consultationTime]
  )

  const latestDataFilter = useDataFilter(
    Object.values(LatestData),
    defaults[ConsultationsFilter.latestData],
    currentFilters[ConsultationsFilter.latestData]
  )

  const filters = useMemo(() => {
    return {
      [ConsultationsFilter.patientAccountLink]:
        patientAccountLinkFilter.selected,
      [ConsultationsFilter.consultationTime]: consultationTimeFilter.selected,
      [ConsultationsFilter.latestData]: latestDataFilter.selected
    }
  }, [
    patientAccountLinkFilter.selected,
    consultationTimeFilter.selected,
    latestDataFilter.selected
  ])

  const resetFilters = () => {
    patientAccountLinkFilter.reset()
    consultationTimeFilter.reset()
    latestDataFilter.reset()
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    onChange(filters)
  }, [filters, onChange])

  return (
    <FiltersWrapper>
      <FilterSection>
        <DataFilter
          title={t('consultations_patient_account_link_title')}
          filter={patientAccountLinkFilter}
          slug={ConsultationsFilter.patientAccountLink}
          type={DataFilterType.singleSelect}
        />
        <DataFilter
          title={t('consultations_consultation_time_title')}
          filter={consultationTimeFilter}
          slug={ConsultationsFilter.consultationTime}
          type={DataFilterType.singleSelect}
        />
        <DataFilter
          title={t('consultations_latest_data_title')}
          filter={latestDataFilter}
          slug={ConsultationsFilter.latestData}
          type={DataFilterType.singleSelect}
        />
      </FilterSection>
      <TopRight>
        <FilterFunctionButton
          handleClick={resetFilters}
          icon={faRotateRight}
          text={t('Reset to defaults')}
        />
      </TopRight>
    </FiltersWrapper>
  )
}
