import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DoseRecommendationStateValue } from '../../../core/api/getDoseRecommendations'
import { DoseChangeMessage } from './DoseChangeMessage/DoseChangeMessage'
import { DoseEditor } from './DoseEditor/DoseEditor'
import { DoseEditorState } from './DoseEditor/DoseEditorBadge/DoseEditorBadge'

const Wrapper = styled.div<{ $largeChange: boolean }>`
  padding: 1.5rem;
  background-color: var(--element-bg);
  min-width: 26.25rem;
  max-width: 30rem;
  border-radius: 1rem;
  height: calc(100% - 3.5rem);
  box-sizing: border-box;
  border: ${(props) =>
    props.$largeChange
      ? '0.125rem solid var(--yellow)'
      : '0.125rem solid var(--element-bg)'};
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`
const Title = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 1.5rem;
`

export const DoseRecommender = ({
  previousDose,
  originalNextDose,
  nextDose,
  setNextDose,
  largeChange,
  doseState
}: {
  previousDose: number | null
  originalNextDose: number
  nextDose: number
  setNextDose: Dispatch<SetStateAction<number>>
  largeChange: boolean
  doseState: DoseRecommendationStateValue
}) => {
  const [state, setState] = useState<DoseEditorState>(DoseEditorState.auto)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  useEffect(() => {
    if (originalNextDose === nextDose) {
      setState(DoseEditorState.auto)
    } else {
      setState(DoseEditorState.edited)
    }
    if (inputRef.current !== null) {
      if (nextDose === 1000) {
        inputRef.current.value = '999'
      } else {
        inputRef.current.value = nextDose.toString()
      }
    }
  }, [nextDose, originalNextDose])

  return (
    <Wrapper $largeChange={largeChange}>
      <Container>
        <Title>{t('Recommended next dose')}</Title>
        <DoseEditor
          state={state}
          setState={setState}
          nextDose={nextDose}
          setNextDose={setNextDose}
          originalNextDose={originalNextDose}
          inputRef={inputRef}
          doseState={doseState.state}
        />
        <DoseChangeMessage
          state={state}
          previousDose={previousDose}
          nextDose={nextDose}
          doseState={doseState}
        />
      </Container>
    </Wrapper>
  )
}
