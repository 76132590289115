import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../Modal/Modal'

const Wrapper = styled.div`
  display: flex;
  width: 31.25rem;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.5rem;
  background-color: var(--white-color);
`

const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`

const Text = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-wrap;
`

const Button = styled.div`
  border-radius: 0.5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
`

const CancelButton = styled(Button)`
  background-color: var(--destructive);
  color: var(--white-color);
  &:hover {
    background-color: var(--destructive-darker);
  }
`
const AcceptButton = styled(Button)`
  background-color: var(--element-bg);
  color: var(--text-primary);
  border: 0.0625rem solid var(--text-lightest);
  &:hover {
    background-color: var(--element-bg-dark);
  }
`

interface GracePeriodModalProps {
  nextDose: number
  closeFun: () => void
  handleSetDose: () => void
}

export const GracePeriodModal = ({
  nextDose,
  closeFun,
  handleSetDose
}: GracePeriodModalProps) => {
  const { t } = useTranslation()
  return (
    <ModalComponent closeFun={closeFun} closetestid="closeGracePeriodModal">
      <Wrapper>
        <Title>{t('grace_period_modal_title')}</Title>
        <Text>{t('grace_period_modal_body')}</Text>
        <AcceptButton onClick={handleSetDose}>
          {t('prescribe_new_dose', { newDose: nextDose })}
        </AcceptButton>
        <CancelButton>{t('Cancel')}</CancelButton>
      </Wrapper>
    </ModalComponent>
  )
}
