import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenu } from '../../ContextMenu/ContextMenu'
import { ConsultationsData } from '../constants/constants'
import { ConsultationsOptionsPatient } from './ConsultationsOptionsPatient'
import { ConsultationsOptionsProvider } from './ConsultationsOptionsProvider'

export function ConsultationsOptions({
  row,
  onPatientCreated
}: {
  row: ConsultationsData
  onPatientCreated: () => void
}) {
  const { t } = useTranslation()
  const items: ReactNode[] = useMemo(() => {
    const list = [
      <ConsultationsOptionsPatient
        patientId={row.patientId}
        mrn={row.importedMRN}
        name={row.importedName}
        onPatientCreated={onPatientCreated}
      ></ConsultationsOptionsPatient>,
      <ConsultationsOptionsProvider row={row}></ConsultationsOptionsProvider>
    ]

    return list
  }, [onPatientCreated, row])

  return <ContextMenu items={items} />
}
