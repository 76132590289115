import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ConsultationsAccountButtonProps,
  ConsultationsData,
  PatientAccountLink
} from '../constants/constants'
import { AbbottLibreviewConsultationsButton } from './AbbottLibreviewConsultations/AbbottLibreviewConsultationsButton'
import {
  NoAccountText,
  NoAccountWrapper
} from './ConsultationsAccountLinkStyledComponents/ConsultationsAccountLinkStyledComponents'
import { DexcomConsultationsButton } from './DexcomConsultationsButton/DexcomConsultationsButton'
import { FitbitConsultationsButton } from './FitbitConsultationsButton/FItbitConsultationsButton'
import { MedtronicCarelinkConsultationsButton } from './MedtronicCarelinkConsultations/MedtronicCarelinkConsultationsButton'
import { YpsomedConsultationsButton } from './YpsomedConsultationsButton/YpsomedConsultationsButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.375rem;
`
export const ConsultationsAccountLinkButton = ({
  provider,
  row,
  inOptionsMenu
}: ConsultationsAccountButtonProps) => {
  const getProviderButton = () => {
    switch (provider.providerType) {
      case PatientAccountLink.libreview:
        return (
          <AbbottLibreviewConsultationsButton
            provider={provider}
            row={row}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case PatientAccountLink.carelink:
        return (
          <MedtronicCarelinkConsultationsButton
            provider={provider}
            row={row}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case PatientAccountLink.dexcom:
        return (
          <DexcomConsultationsButton
            provider={provider}
            row={row}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case PatientAccountLink.ypsomed:
        return (
          <YpsomedConsultationsButton
            provider={provider}
            row={row}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case PatientAccountLink.fitbit:
        return (
          <FitbitConsultationsButton
            provider={provider}
            row={row}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case PatientAccountLink.notlinked:
        return null
      case PatientAccountLink.notregistered:
        return null
    }
  }

  return <>{getProviderButton()}</>
}

export const ConsultationsAccountLink = ({
  row
}: {
  row: ConsultationsData
}) => {
  const { t } = useTranslation()

  return (
    <>
      {row.providers ? (
        <Wrapper>
          {row.providers.map((provider) => (
            <ConsultationsAccountLinkButton
              provider={provider}
              row={row}
              inOptionsMenu={false}
            />
          ))}
        </Wrapper>
      ) : (
        <NoAccountWrapper>
          <NoAccountText>{t('no_linked_accounts')}</NoAccountText>
        </NoAccountWrapper>
      )}
    </>
  )
}
