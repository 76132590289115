import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import {
  BasalInjectionInfo,
  NovoPenObject
} from '../../../core/api/getDoseRecommendations'
import { useSessionContext } from '../../../core/contexts/session-context'
import dayjs from '../../../core/dayjs/dayjs'
import { UserType } from '../../../core/entities/user.entity'
import { formatNumber } from '../../../helpers/helpers'
import {
  InformationLogoSize,
  InformationTooltip
} from '../../InformationTooltip/InformationTooltip'
import { Modal } from '../../Modal/Modal'
import { FindDeviceId } from '../../SmartPenInfo/FindDeviceId/FindDeviceId'
import {
  getMedicineString,
  MedicineTag
} from '../../SmartPenInfo/Medicine/Medicine'
import { MorePenInfo } from '../../SmartPenInfo/MorePenInfo/MorePenInfo'
import { PenImage, PenImageSize } from '../../SmartPenInfo/SmartPenInfo'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'
import { InjectionsRecentDays } from './InjectionsRecentDays/InjectionsRecentDays'
const Wrapper = styled.div<{ $isPatient: boolean }>`
  padding: 0.5rem;
  background-color: var(--element-bg);
  width: ${(props) => (props.$isPatient ? '50%' : '100%')};
  border-radius: 1rem;
  height: 100%;
  box-sizing: border-box;
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 33%;
`

const PenInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
`

const Key = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-lighter);
  line-height: 1.5rem;
`

const Value = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.5rem;
`
const Unit = styled(Value)`
  color: var(--text-medium);
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

const Button = styled.div`
  display: flex;
  height: 2.25rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--white-color);
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  color: var(--text-primary);
  &:hover {
    background-color: var(--blue);
    color: var(--white-color);
  }
`
const InfoIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const TitleIconWrapper = styled.div`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
`
const QuestionIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: var(--brand-primary-color);
`
export const PenSection = ({
  smartPen,
  isPatient,
  basalInjectionInfo
}: {
  smartPen: NovoPenObject | null
  isPatient: boolean
  basalInjectionInfo: BasalInjectionInfo
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false)
  const [showFindDeviceId, setShowFindDeviceId] = useState<boolean>(false)
  const { user } = useSessionContext()
  const { t } = useTranslation()
  return (
    <Wrapper $isPatient={isPatient}>
      {smartPen === null ? (
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      ) : (
        <>
          <Modal isOpen={showMoreInfo}>
            <MorePenInfo
              closeFun={() => setShowMoreInfo(false)}
              smartPen={smartPen}
            ></MorePenInfo>
          </Modal>
          <Modal isOpen={showFindDeviceId}>
            <FindDeviceId
              closeFun={() => setShowFindDeviceId(false)}
            ></FindDeviceId>
          </Modal>
          <Container>
            {PenImage(smartPen.type, PenImageSize.small)}
            <PenInfo>
              <Section>
                <Column>
                  <Key>{t('pen_name')}</Key>
                  <Row>
                    <Value>{smartPen.name}</Value>
                    <InformationTooltip
                      paragraph={[
                        {
                          title: t('Device name'),
                          body: t(
                            user?.type === UserType.Patient
                              ? 'The name of the NovoPen set by you.'
                              : 'The name of the NovoPen set by the patient.'
                          )
                        }
                      ]}
                      size={InformationLogoSize.medium}
                      logoStyling={{ color: 'var(--brand-primary-color)' }}
                    />
                  </Row>
                </Column>
                <Column>
                  <Key>{t('pen_id')}</Key>
                  <Row>
                    <Value>{smartPen.hardwareId}</Value>
                    <TitleIconWrapper
                      data-testid="smartPenShowFindDeviceId"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowFindDeviceId(true)}
                    >
                      <QuestionIcon icon={faCircleQuestion} />
                    </TitleIconWrapper>
                  </Row>
                </Column>
                <Row>
                  <Button onClick={() => setShowMoreInfo(true)}>
                    <InfoIcon icon={faInfoCircle} />
                  </Button>
                </Row>
              </Section>

              <Section>
                <Column>
                  <Key>{t('Medicine')}</Key>
                  <TitleWrapper>
                    <MedicineTag medicineType={smartPen.type} />
                    <Value>
                      {getMedicineString({
                        medicineType: smartPen.type,
                        shortString: true
                      })}
                    </Value>
                  </TitleWrapper>
                </Column>
                <Column>
                  <Key>{t('Last connection')}</Key>
                  <Row>
                    <Value>
                      {dayjs(smartPen.lastConnectionZoned).fromNow()}
                    </Value>
                  </Row>
                </Column>
              </Section>

              <Section>
                <Column>
                  <Row>
                    <Key>{t('doses_made_days')}</Key>
                    <InformationTooltip
                      paragraph={[
                        {
                          title: t('doses_made_days'),
                          body: t('doses_made_days_tooltip_body')
                        }
                      ]}
                      size={InformationLogoSize.medium}
                      logoStyling={{ color: 'var(--brand-primary-color)' }}
                    />
                  </Row>
                  <InjectionsRecentDays
                    basalInjectionInfo={basalInjectionInfo}
                  />
                </Column>
                <Column>
                  <Row>
                    <Key>{t('daily_total_dose')}</Key>
                    <InformationTooltip
                      paragraph={[
                        {
                          title: t('daily_total_dose'),
                          body: t('daily_total_dose_tooltip_body')
                        }
                      ]}
                      size={InformationLogoSize.medium}
                      logoStyling={{ color: 'var(--brand-primary-color)' }}
                    />
                  </Row>
                  <Row>
                    <Value>
                      {basalInjectionInfo?.avgDailyDose
                        ? formatNumber({
                            value: basalInjectionInfo.avgDailyDose,
                            decimals: 0
                          })
                        : '--'}
                    </Value>
                    <Unit>{t('u_average')}</Unit>
                  </Row>
                </Column>
              </Section>
            </PenInfo>
          </Container>
        </>
      )}
    </Wrapper>
  )
}
