import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { RecentDayInjectionItemTooltip } from './RecentDayInjectionItemTooltip/RecentDayInjectionItemTooltip'

const Item = styled.div<{ $bgColor: string }>`
  display: flex;
  padding: 0.25rem 0.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.$bgColor};
`

const RelativeContainer = styled.div`
  position: relative;
`

export const RecentDayInjectionItemWrapper = ({
  children,
  bgColor,
  hoverBgColor,
  daysAgo,
  total
}: {
  children: ReactNode
  bgColor: string
  hoverBgColor: string
  daysAgo: number
  total: number | null
}) => {
  const [hovered, setHovered] = useState(false)
  return (
    <RelativeContainer>
      {hovered && (
        <RecentDayInjectionItemTooltip daysAgo={daysAgo} total={total} />
      )}
      <Item
        $bgColor={hovered ? hoverBgColor : bgColor}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {children}
      </Item>
    </RelativeContainer>
  )
}
