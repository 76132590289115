import { Cell, flexRender, Row } from '@tanstack/react-table'
import { useState } from 'react'
import { Td } from '../../TableStyledElements/TableStyledElements'

export function TableRow<T>({ row }: { row: Row<T> }) {
  const [hovered, setHovered] = useState(false)

  function getRowContent<T>({ cell }: { cell: Cell<T, unknown> }) {
    if (cell.column.id === 'options') {
      return hovered ? (
        flexRender(cell.column.columnDef.cell, cell.getContext())
      ) : (
        <></>
      )
    } else {
      return flexRender(cell.column.columnDef.cell, cell.getContext())
    }
  }
  return (
    <tr>
      {row.getVisibleCells().map((cell) => (
        <Td
          key={cell.id}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          $context={cell.column.id === 'upload_context'}
          $options={cell.column.id === 'options'}
        >
          {getRowContent({ cell })}
        </Td>
      ))}
    </tr>
  )
}
