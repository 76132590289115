import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { RelativeWrapper } from '../DeviceList/DeviceList'

import {
  PeriodObject,
  getLatestData
} from '../Header/DevicesAndPeriod/DevicesAndPeriod'
import { WarningBox, WarningType } from '../WarningComponent/WarningBox'
import { ButtonSection } from './ButtonSection/ButtonSection'
import { Header } from './Header/Header'
import { TimeLine } from './TimeLine/TimeLine'

export type timeDataObject = {
  start: string
  end: string
  sufficientData: boolean
}
export type HeaderProps = {
  cancel: () => void
  apply: () => void
  period: PeriodObject | null
  periodState: PeriodObject | null
}

export type ButtonProps = {
  name: string
  index: number
  active: number
  handleActive: (key: number, text: string, date: Date) => void
  text: string
  setEndDate: Dispatch<SetStateAction<Date | null>>
  scrollWrapper: MutableRefObject<HTMLDivElement>
  latestData: Date | null
  data: Array<timeDataObject> | null
  periodState: PeriodObject | null
}
export type ButtonSectionProps = {
  name: string
  title: string
  buttons: Array<string>
  periodState: PeriodObject | null
  setPeriodState: Dispatch<SetStateAction<PeriodObject | null>>
  endDate: Date | null
  setEndDate: Dispatch<SetStateAction<Date | null>>
  scrollWrapper: MutableRefObject<HTMLDivElement>
  latestData: Date | null
  data: Array<timeDataObject> | null
}

type ButtonCSSProps = {
  activeState: boolean
}
type TimeSelectorProps = {
  handleTimeSelector: () => void
  icon: any
  handlePeriod: (state: PeriodObject) => void
  period: PeriodObject | null
  timeData: Array<timeDataObject> | null
  oldData: boolean
}
const TimeSelectorWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: var(--white-color);
  box-shadow: 0rem 0.25rem 1rem rgba(51, 51, 51, 0.16);
  border-radius: 1.75rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6.25rem;
  gap: 1.5rem;
`
export const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
export const SideTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-primary);
  gap: 0.5rem;
  align-items: center;
`
export const Title = styled.div`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
`
export const DateIcon = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 1rem;
  color: inherit;
  margin-left: 0.75rem;
`

export const DatePickerWrapper = styled.div`
  position: relative;
  width: 6rem;
`
export const CustomDatePicker = styled(DatePicker)`
  color: inherit;
  background: inherit;
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: inherit;
  border: none;
  margin: 0 auto;
  text-align: left;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  position: absolute;
  width: 100%;
  left: 0;
  transform: translateY(-75%);
`
export const TimeSelector = ({
  handleTimeSelector,
  icon,
  handlePeriod,
  period,
  timeData,
  oldData
}: TimeSelectorProps) => {
  const { t } = useTranslation()
  const [data] = useState(timeData)
  const [endDate, setEndDate] = useState<Date | null>(
    period ? period.endDate : null
  )
  const [latestData] = useState(
    period && data ? dayjs(data[getLatestData(data)].end).toDate() : null
  )
  const [periodState, setPeriodState] = useState<PeriodObject | null>(
    period
      ? {
          lengthOfPeriod: period.lengthOfPeriod,
          endDate: period.endDate,
          deviceID: period.deviceID
        }
      : null
  )
  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        handleTimeSelector()
      }
    }
    window.addEventListener('keyup', close)
    return () => window.removeEventListener('keyup', close)
  }, [handleTimeSelector])

  const scrollWrapper = useRef<HTMLDivElement>(document.createElement('div'))
  return (
    <RelativeWrapper
      data-testid="closeTimeSelector"
      onClick={handleTimeSelector}
    >
      <TimeSelectorWrapper
        onClick={function (event) {
          event.stopPropagation()
        }}
      >
        <Header
          cancel={() => handleTimeSelector()}
          apply={periodState ? () => handlePeriod(periodState) : () => null}
          period={period ?? null}
          periodState={periodState}
        />
        {oldData && (
          <WarningBox
            text={t('latest_data_14_days_old')}
            warningType={WarningType.TimeSelector}
          ></WarningBox>
        )}
        <SectionWrapper>
          <ButtonSection
            name={'period'}
            title={`${t('Choose Length of Period')}`}
            buttons={['7', '14', '30', '90']}
            periodState={periodState ?? null}
            setPeriodState={setPeriodState}
            endDate={endDate}
            setEndDate={setEndDate}
            scrollWrapper={scrollWrapper}
            latestData={latestData}
            data={data}
          ></ButtonSection>
          <ButtonSection
            name={'endDate'}
            title={t('Choose End Date')}
            buttons={['Custom', t('Latest Data')]}
            periodState={periodState}
            setPeriodState={setPeriodState}
            endDate={endDate}
            setEndDate={setEndDate}
            scrollWrapper={scrollWrapper}
            latestData={latestData}
            data={data}
          ></ButtonSection>
        </SectionWrapper>
        <TimeLine
          icon={icon}
          periodState={periodState}
          data={data}
          scrollWrapper={scrollWrapper}
        />
      </TimeSelectorWrapper>
    </RelativeWrapper>
  )
}
