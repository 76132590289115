import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLibreConsultations } from '../../core/api/consultations/get-libre-consultations'
import { PaginatedResponse } from '../../core/api/pagination'
import { useFetch } from '../../hooks/useFetch'
import { useFiltersCount } from '../../hooks/useFiltersCount'

import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { FilterFunctionButton } from '../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableSearchBar } from '../TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  TableHeader,
  TableWrapper
} from '../TableComponents/TableStyledElements/TableStyledElements'
import { Title } from '../Title/Title'
import {
  ConsultationsData,
  ConsultationsFilter,
  ConsultationsParams,
  ConsultationTime,
  LatestData,
  PatientAccountLink
} from './constants/constants'
import { ConsultationsAccountLink } from './ConsultationsAccountLink/ConsultationsAccountLink'
import { ConsultationsFilters } from './ConsultationsFilters/ConsultationsFilters'
import { ConsultationsOptions } from './ConsultationsOptions/ConsultationsOptions'
import {
  ConsultationsSummary,
  ConsultationsSummaryData
} from './ConsultationsSummary/ConsultationsSummary'
import { ConsultationTable } from './ConsultationsTable/ConsultationsTable'
import { MrnField } from './ConsultationsTable/MrnField/MrnField'
import { NameField } from './ConsultationsTable/NameField/NameField'
import { ImportConsultations } from './ImportConsultations/ImportConsultations'

const defaults = {
  [ConsultationsFilter.consultationTime]: ConsultationTime.within2days,
  [ConsultationsFilter.latestData]: LatestData.olderthan2days,
  [ConsultationsFilter.patientAccountLink]: PatientAccountLink.libreview
}

export const Consultations = () => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'consultation_date', desc: false }
  ])
  const [filters, setFilters] = useState<{ [key: string]: string | string[] }>(
    defaults
  )
  const [summary, setSummary] = useState<ConsultationsSummaryData | undefined>(
    undefined
  )
  const [refreshSummary, setRefreshSummary] = useState(false)
  const filtersCount = useFiltersCount(filters)

  const parameters = useMemo(
    () => ({ ...filters, searchString, importId: summary?.id ?? '', sorting }),
    [filters, searchString, summary, sorting]
  )
  const { response, error, status, pagination, setPagination, refresh } =
    useFetch<PaginatedResponse<ConsultationsData>, ConsultationsParams>(
      getLibreConsultations,
      parameters
    )

  const columns: ColumnDef<ConsultationsData>[] = useMemo(
    () => [
      {
        id: 'consultation_date',
        header: t('consultation_table_header_title_1'),
        sortingFn: 'datetime',
        accessorFn: (row: ConsultationsData) =>
          dayjs(row.consultationTime).format('DD/MM/YYYY')
      },
      {
        id: 'time',
        header: t('consultation_table_header_title_2'),
        enableSorting: false,
        accessorFn: (row: ConsultationsData) =>
          dayjs(row.consultationTime).format('HH:mm')
      },
      {
        id: 'name',
        header: t('consultation_table_header_title_3'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <NameField row={row.original} />
        )
      },
      {
        id: 'mrn',
        header: t('consultation_table_header_title_4'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <MrnField onPatientCreated={() => refresh()} row={row.original} />
        )
      },
      {
        id: 'account_links',
        header: t('consultation_table_header_title_5'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <ConsultationsAccountLink row={row.original} />
        )
      },
      {
        id: 'latest_data',
        header: t('consultation_table_header_title_6'),
        enableSorting: false,
        accessorFn: (row: ConsultationsData) =>
          row.latestData ? dayjs(row.latestData).format('DD/MM/YYYY') : '-' // ASK SIMON ABOUT EMPTY STATE
      },
      {
        id: 'options',
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <ConsultationsOptions
            row={row.original}
            onPatientCreated={() => refresh()}
          />
        )
      }
    ],
    [refresh, t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: { pagination, sorting },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getRowId: (originalRow) => originalRow.id,
    enableSortingRemoval: false
  })

  return (
    <ErrorBoundary>
      <TableWrapper>
        <TableHeader>
          <HeaderTopSection>
            <Title>{t('consultations_title')}</Title>
            <HeaderTopSectionButtons>
              <TableSearchBar handleChange={setSearchString} />
              <FilterFunctionButton
                filtersCount={filtersCount}
                handleClick={handleFilterExpand}
                icon={faBarsFilter}
                text={t('Filters')}
              />
              <ImportConsultations
                setSummary={setSummary}
                setRefreshSummary={setRefreshSummary}
              />
              <ConsultationsSummary
                summary={summary}
                setSummary={setSummary}
                refreshSummary={refreshSummary}
                getLatestSummary={false}
              />
            </HeaderTopSectionButtons>
          </HeaderTopSection>

          {showFilters && (
            <ConsultationsFilters
              defaults={defaults}
              currentFilters={filters}
              onChange={setFilters}
            />
          )}
        </TableHeader>
        <ConsultationTable
          table={table}
          context={
            <ConsultationsSummary
              summary={summary}
              setSummary={setSummary}
              refreshSummary={refreshSummary}
              getLatestSummary={true}
            />
          }
          fetchStatus={status}
          fetchError={error}
        ></ConsultationTable>
      </TableWrapper>
    </ErrorBoundary>
  )

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }
}
