import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  InformationLogoSize,
  InformationTooltip
} from '../InformationTooltip/InformationTooltip'
import { Title } from '../Title/Title'

export enum PageTitles {
  trends
}

interface PageTitleProps {
  type: PageTitles
  showTooltip: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

export const PageTitle = ({ type, showTooltip }: PageTitleProps) => {
  const { t } = useTranslation()

  const getConfig = () => {
    switch (type) {
      case PageTitles.trends:
        return {
          title: t('Trends'),
          paragraph: [
            {
              title: t('Trends'),
              body: t('trends_title_tooltip')
            }
          ]
        }
    }
  }

  return (
    <Wrapper>
      <Title>{getConfig().title}</Title>
      {showTooltip === true && (
        <InformationTooltip
          paragraph={getConfig().paragraph}
          size={InformationLogoSize.medium}
        />
      )}
    </Wrapper>
  )
}
