import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const Icon = styled(FontAwesomeIcon)`
  color: var(--green);
  text-align: center;
  font-size: 0.875rem;
  line-height: 2.25rem;
`
export const Text = styled.span`
  color: var(--text-primary);
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.25rem;
`
export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0.75rem 0rem 0.5rem;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-white);
  box-sizing: border-box;
  height: 2.25rem;
  text-decoration: none;
`
export const HoverableWrapper = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0.75rem 0rem 0.5rem;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-white);
  box-sizing: border-box;
  height: 2.25rem;
  cursor: pointer;
  &:hover {
    background-color: var(--brand-primary-color);
    border: 0.0625rem solid var(-brand-darker-color);
  }
  &:hover ${Icon} {
    color: var(--white-color);
  }

  &:hover ${Text} {
    color: var(--white-color);
  }
  text-decoration: none;
`
export const NoAccountWrapper = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  display: inline-flex;
  padding: 0rem 0.75rem;
  align-items: flex-start;
  gap: 0.375rem;
  box-sizing: border-box;
  height: 2.25rem;
`

export const NoAccountText = styled.span`
  color: var(--text-lighter);
  text-align: center;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.25rem;
`
