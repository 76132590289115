import { faCalendarCircleUser } from '@fortawesome/pro-solid-svg-icons'
import saveAs from 'file-saver'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getConsultationsSummaryOriginalFile } from '../../../../core/api/consultations/get-consultations-summary-original-file'
import { useDownloader } from '../../../../hooks/use-downloader'
import {
  Button,
  ButtonIcon,
  ButtonText,
  ConsultationsSummaryData
} from '../ConsultationsSummary'

const NoImportWrapper = styled.div`
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  height: 3.25rem;
  box-sizing: border-box;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--element-bg-80);
  backdrop-filter: blur(0.5rem);
`
const NoImportText = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`
interface ImportedFileComponentProps {
  summary: null | undefined | ConsultationsSummaryData
}

export const ImportedFileComponent = ({
  summary
}: ImportedFileComponentProps) => {
  const { state, download, reset } = useDownloader()
  const { t } = useTranslation()
  async function downloadConsultationsOriginalFile() {
    const data = await download(
      (progressCb: (progress: ProgressEvent) => void) => {
        return getConsultationsSummaryOriginalFile(
          {
            id: summary?.id ?? ''
          },
          progressCb
        )
      }
    )
    if (data) {
      saveAs(data, 'original_import_file.csv')
    }
  }

  return summary ? (
    <Button onClick={downloadConsultationsOriginalFile}>
      <ButtonIcon icon={faCalendarCircleUser} />
      <ButtonText>
        {t('consultation_summary_original_csv_button_text')}
      </ButtonText>
    </Button>
  ) : (
    <NoImportWrapper>
      <NoImportText>{t('based_on_csv_no_data')}</NoImportText>
    </NoImportWrapper>
  )
}
