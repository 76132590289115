import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUploads } from '../../core/api/get-uploads'
import { PaginatedResponse } from '../../core/api/pagination'
import { Patient } from '../../core/entities/patient.entity'
import { useFetch } from '../../hooks/useFetch'
import { useFiltersCount } from '../../hooks/useFiltersCount'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { PatientLink } from '../PatientLink/PatientLink'
import { SortEnum } from '../ProviderPatients/ProviderPatients'
import { FilterFunctionButton } from '../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableSearchBar } from '../TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  TableHeader,
  TableWrapper
} from '../TableComponents/TableStyledElements/TableStyledElements'
import { Title } from '../Title/Title'
import {
  ApplicationUploadActivityType,
  dataState,
  Filter,
  Filters,
  Manufacturer,
  TIME_PERIOD_MONTHS,
  Upload
} from './constants'
import { DataTable } from './DataTable'
import { UploadActivityContext } from './UploadActivityContext'
import { UploadActivityFilters } from './UploadActivityFilters'
import { UploadActivityOptions } from './UploadActivityOptions'
import { UploadPlatformContext } from './UploadContext/UploadContext'
import { UploadedBy } from './UploadedBy'

export interface UploadsProps {
  patient: Patient | null
  fetch: (
    orderBy?: SortEnum,
    size?: number,
    patientId?: string
  ) => Promise<Upload[]>
}

export type UploadActivityFilterTypes = Filters & {
  cutoff?: string
  types?: ApplicationUploadActivityType[]
  manufacturers?: Manufacturer[]
}

const manufacturersDefault: string[] = []

const typesDefault: string[] = [
  ApplicationUploadActivityType.ClinicUploader,
  ApplicationUploadActivityType.HomeUploader,
  ApplicationUploadActivityType.App,
  ApplicationUploadActivityType.Legacy,
  ApplicationUploadActivityType.File
]

const defaults = {
  [Filter.types]: typesDefault,
  [Filter.manufacturers]: manufacturersDefault
}

export function UploadActivity() {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [sorting] = useState<SortingState>([{ id: 'upload_date', desc: false }])
  const params = useMemo(
    () => ({
      cutoff: dayjs().subtract(TIME_PERIOD_MONTHS, 'months').format()
    }),
    []
  )
  const [filters, setFilters] = useState<{ [key: string]: string[] | string }>(
    defaults
  )
  const filtersCount = useFiltersCount(filters)

  const parameters = useMemo(
    () => ({ ...filters, ...params, searchString }),
    [filters, params, searchString]
  )
  const { response, error, status, pagination, setPagination, refresh } =
    useFetch<PaginatedResponse<Upload>, UploadActivityFilterTypes>(
      getUploads,
      parameters
    )

  const columns: ColumnDef<Upload>[] = useMemo(
    () => [
      {
        id: 'upload_date',
        header: t('Date'),
        sortDescFirst: true,
        accessorFn: (row: Upload) =>
          row.uploaded
            ? dayjs(row.uploaded).format('DD/MM/YYYY')
            : dataState.empty
      },
      {
        id: 'time',
        header: t('Time'),
        enableSorting: false,
        accessorFn: (row: Upload) =>
          row.uploaded
            ? dayjs(row.uploaded).format('HH:mm').toString()
            : dataState.empty
      },
      {
        id: 'cpr',
        header: t('CPR'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) => (
          <PatientLink patientId={row.original.patientId}>
            {row.original.patientMRN.substring(0, 6) +
              '-' +
              row.original.patientMRN.substring(6, 11)}
          </PatientLink>
        )
      },
      {
        id: 'patient_name',
        header: t('Patient name'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) => (
          <PatientLink patientId={row.original.patientId}>
            {row.original.patientName}
          </PatientLink>
        )
      },
      {
        id: 'manufacturer',
        header: t('Manufacturer'),
        enableSorting: false,
        accessorFn: (row: Upload) =>
          row.manufacturer ? row.manufacturer : dataState.empty
      },
      {
        id: 'device',
        header: t('Device'),
        enableSorting: false,
        accessorFn: (row: Upload) => row.deviceContext.modelDisplayName
      },
      {
        id: 'upload_context',
        header: t('Context'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) => (
          <UploadPlatformContext context={row.original.uploadContext} />
        ),
        size: 150
      },
      {
        id: 'upload_user',
        header: t('Upload user'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) =>
          row.original.user ? (
            <UploadedBy uploadedByUser={row.original.user} />
          ) : (
            <>-</>
          )
      },
      {
        id: 'options',
        cell: ({ row }: { row: Row<Upload> }) => (
          <UploadActivityOptions
            upload={row.original}
            onDelete={() => refresh()}
          />
        )
      }
    ],
    [refresh, t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: { pagination, sorting },
    onPaginationChange: setPagination,
    onSortingChange: () => ({}),
    getRowId: (originalRow) => originalRow.id
  })

  return (
    <ErrorBoundary>
      <TableWrapper>
        <TableHeader>
          <HeaderTopSection>
            <Title>{t('Upload Activity')}</Title>
            <HeaderTopSectionButtons>
              <TableSearchBar handleChange={setSearchString} />
              <FilterFunctionButton
                filtersCount={filtersCount}
                handleClick={handleFilterExpand}
                icon={faBarsFilter}
                text={t('Filters')}
              />
            </HeaderTopSectionButtons>
          </HeaderTopSection>

          {showFilters && (
            <UploadActivityFilters
              defaults={defaults}
              currentFilters={filters}
              onChange={setFilters}
            />
          )}
        </TableHeader>
        <DataTable
          context={<UploadActivityContext />}
          table={table}
          fetchError={error}
          fetchStatus={status}
        />
      </TableWrapper>
    </ErrorBoundary>
  )

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }
}
