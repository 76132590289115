import { faCircleCheck, faCircleX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export enum BasalInjectionsIconSize {
  small,
  medium
}

const PenInjectionsIconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const PenInjectionsIcon = styled(FontAwesomeIcon)<{
  $color: string
  $size: BasalInjectionsIconSize
}>`
  font-size: ${(props) =>
    props.$size === BasalInjectionsIconSize.small ? '0.75rem' : '1.125rem'};
  line-height: ${(props) =>
    props.$size === BasalInjectionsIconSize.small ? '1rem' : '1.5rem'};
  color: ${(props) => props.$color};
`

export const BasalInjectionIcon = ({
  injected,
  size
}: {
  injected: boolean
  size: BasalInjectionsIconSize
}) => {
  const color = injected ? 'var(--green)' : 'var(--destructive-darker)'
  const icon = injected ? faCircleCheck : faCircleX

  if (size === BasalInjectionsIconSize.small) {
    return (
      <PenInjectionsIconWrapper>
        <PenInjectionsIcon
          $color={color}
          icon={icon}
          $size={size}
        ></PenInjectionsIcon>
      </PenInjectionsIconWrapper>
    )
  } else {
    return (
      <PenInjectionsIcon
        $color={color}
        icon={icon}
        $size={size}
      ></PenInjectionsIcon>
    )
  }
}
