import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { BasalInjectionWidgetData } from '../BasalInjection'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  align-items: center;
  justify-content: center;
  background-color: var(--element-bg);
  width: 100%;
  border-radius: 0.5rem;
  box-sizing: border-box;
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ValueAndUnit = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.125rem;
`
const Text = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--text-primary);
`

const Value = styled(Text)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 0.75rem;
`
const Unit = styled(Value)`
  font-size: 0.625rem;
  color: var(--text-lighter);
`
const BarContainer = styled.div`
  width: calc(100% - 2.5rem);
  border-radius: 0.125rem;
  border: 0.0625rem solid var(--text-lightest);
  height: 0.75rem;
  box-sizing: border-box;
  position: relative;
`
const Bar = styled.div<{ $width: number }>`
  width: ${(props) => props.$width}%;
  height: 100%;
  border-radius: 0.125rem;
  background-color: var(--basal-color);
  border: 0.0625rem solid var(--basal-color);
  position: absolute;
  left: -0.0625rem;
  top: -0.0625rem;
`

export const InjectionsMade = ({
  data
}: {
  data: BasalInjectionWidgetData
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Section>
        <Text>{t('days_with_registered_doses')}</Text>
        <Text>{`${data.daysWithInjections} / ${data.totalDays} ${t(
          'days'
        )}`}</Text>
      </Section>
      <Section>
        <BarContainer>
          <Bar $width={data.daysWithInjectionsPercentage}></Bar>
        </BarContainer>
        <ValueAndUnit>
          <Value data-testid="basalInjectionDaysWithInjectionsPercentage">
            {formatNumber({
              value: data.daysWithInjectionsPercentage,
              decimals: 0
            })}
          </Value>
          <Unit>%</Unit>
        </ValueAndUnit>
      </Section>
    </Wrapper>
  )
}
