import { Table } from '@tanstack/react-table'
import { ReactNode } from 'react'
import { StyledComponent } from 'styled-components'
import { FetchStatus } from '../../../hooks/useFetch'
import { RowPlaceholder } from '../Misc/RowPlaceholder'
import { Pagination } from '../Pagination/Pagination'
import { DataTableEmpty } from '../TableStates/DataTableEmpty'
import { DataTableError } from '../TableStates/DataTableError'
import { DataTableLoading } from '../TableStates/DataTableLoading'
import { FooterStack } from '../TableStyledElements/TableStyledElements'
import { TableRow } from './TableRow/TableRow'

export function TableLayout<T>({
  table,
  context = null,
  fetchStatus,
  fetchError,
  children,
  usesPagination,
  WrapperElement
}: Readonly<{
  table: Table<T>
  context: ReactNode
  fetchStatus: FetchStatus
  fetchError?: Error | null
  children: ReactNode
  usesPagination: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  WrapperElement: StyledComponent<'table', any, {}, never>
}>) {
  return (
    <>
      <WrapperElement>
        {children}
        <tbody>
          {fetchStatus === FetchStatus.Success && table.getRowCount() > 0 && (
            <>
              {table.getRowModel().rows.map((row) => (
                <TableRow row={row} key={row.id} />
              ))}
              <RowPlaceholder
                colSpan={table.getFlatHeaders().length}
                currentPageRowCount={table.getRowModel().rows.length}
                rowsPerPage={
                  usesPagination
                    ? table.getState().pagination.pageSize
                    : table.getRowModel().rows.length
                }
              ></RowPlaceholder>
            </>
          )}

          {fetchStatus === FetchStatus.Error && fetchError && (
            <RowPlaceholder
              colSpan={table.getFlatHeaders().length}
              currentPageRowCount={table.getRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
            >
              <DataTableError error={fetchError} />
            </RowPlaceholder>
          )}
          {fetchStatus === FetchStatus.Loading && (
            <RowPlaceholder
              colSpan={table.getFlatHeaders().length}
              currentPageRowCount={table.getRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
            >
              <DataTableLoading />
            </RowPlaceholder>
          )}
          {fetchStatus === FetchStatus.Success && table.getRowCount() === 0 && (
            <RowPlaceholder
              colSpan={table.getFlatHeaders().length}
              currentPageRowCount={table.getRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
            >
              <DataTableEmpty />
            </RowPlaceholder>
          )}
        </tbody>
      </WrapperElement>
      <FooterStack>
        <div>{context}</div>
        {usesPagination && <Pagination table={table} />}
      </FooterStack>
    </>
  )
}
