import config from '../../config'
import { AxiosRequest } from '../axios-request'

export async function getConsultationsSummaryOriginalFile(
  params: { id: string },
  onDownload: (progress: ProgressEvent) => void,
  options: object = {}
): Promise<Blob> {
  const url =
    config.API_URL +
    '/consultations_uploads/' +
    params.id +
    '/download_original_file'
  const response = await AxiosRequest.create({
    responseType: 'blob'
  }).get(url, {
    ...options,
    onDownloadProgress: onDownload
  })

  return response.data as Blob
}
