import { faUserPlus, faUserSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { formatCPR } from '../../../../helpers/helpers'
import { CreatePatientModal } from '../../../CreatePatientModal/CreatePatientModal'
import { Modal } from '../../../Modal/Modal'

const IconWrapper = styled.div<{ $hovered: boolean }>`
  display: flex;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  ${(props) =>
    props.$hovered
      ? css`
          background: var(--white-color);
          color: var(--brand-primary-color);
        `
      : css`
          color: var(--yellow);
        `}
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
`

interface NewPatientIconProps {
  mrn: string | null
  name: string | null
  onPatientCreated: () => void
}

export function NewPatientIcon({
  mrn,
  name,
  onPatientCreated
}: NewPatientIconProps) {
  const [showModal, setShowModal] = useState(false)
  const [hovered, setHovered] = useState(false)

  return (
    <>
      <Modal isOpen={showModal}>
        <CreatePatientModal
          mrn={mrn ? formatCPR(mrn) : null}
          name={name}
          closeModal={() => setShowModal(false)}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          refresh={() => onPatientCreated()}
        />
      </Modal>
      <IconWrapper
        $hovered={hovered}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => setShowModal(true)}
      >
        <Icon icon={hovered ? faUserPlus : faUserSlash}></Icon>
      </IconWrapper>
    </>
  )
}
