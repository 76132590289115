import {
  NovoPenBatteryStatus,
  NovoPenMedicine
} from '../../components/Graphs/Insights/interfaces'
import { NovoPenErrorCodes } from '../../components/SmartPenInfo/ErrorComponent/ErrorComponent'
import config from '../config'
import { AxiosRequest } from './axios-request'

export enum DoseRecommendationState {
  Ok = 'Ok',
  LowSensorCoverage = 'LowSensorCoverage',
  Cooldown = 'Cooldown'
}

export interface DoseRecommendationStateValue {
  state: DoseRecommendationState
  daysSinceLastTitration: number | null
  cooldown: number | null
}
export interface DoseRecommendationValue {
  recommendedDose: number
  bgReference: number
  errorValue: number
  state: DoseRecommendationStateValue
  currentDose: number | null
  setBy: string | null
  currentDoseTime: string | null
  currentDoseTimeZone: string | null
}
export interface NovoPenObject {
  id: string | null
  name: string
  hardwareId: string
  lastConnection: string
  batteryStatus: NovoPenBatteryStatus
  modelName: string
  firmwareRevision: string
  type: NovoPenMedicine
  activePatientId: string
  newestUploadId: string
  lastConnectionZoned: string
  memory: number
  events: Array<NovoPenErrorCodes>
  removedByHcp: boolean | null
}

export enum InjectionStatus {
  unknown = 'unknown',
  missed = 'missed',
  injected = 'injected'
}
export interface BasalInjectionInfo {
  days: Array<{
    injectionStatus: InjectionStatus
    total: null | number
    daysAgo: number
  }>
  avgDailyDose: number
}
export interface getDoseRecommendationsResponse {
  doseRecommendation: DoseRecommendationValue | null
  novoPen: NovoPenObject | null
  basalInjectionInfo: BasalInjectionInfo
}

export async function getDoseRecommendations(
  patientId: string
): Promise<getDoseRecommendationsResponse> {
  const response = await AxiosRequest.create().get(
    config.API_URL + `/adaptt2d/${patientId}/doses/recommendations`
  )
  return response.data as getDoseRecommendationsResponse
}
