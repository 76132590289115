import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Wrapper = styled.div<{ $height: string }>`
  box-sizing: border-box;
  height: ${(props) => props.$height};
  padding: 0 1rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background-color: var(--yellow-12);
  border: 0.125rem solid var(--yellow);
  width: 100%;
`

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--yellow);
`
const WarningText = styled.span<{
  $fontSize: string
  $fontWeight: string
  $lineHeight: string
}>`
  font-family: inter;
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
  line-height: ${(props) => props.$lineHeight};
  color: var(--text-primary);
`

export enum WarningType {
  PenTitration,
  TimeSelector
}

export const WarningBox = ({
  text,
  warningType
}: {
  text: string
  warningType: WarningType
}) => {
  const getConfig = () => {
    switch (warningType) {
      case WarningType.PenTitration:
        return {
          height: '3rem',
          fontSize: '0.875rem',
          fontWeight: '600',
          lineHeight: '1.5rem',
          testId: 'warningBoxPenTitration'
        }
      case WarningType.TimeSelector:
        return {
          height: '4.25rem',
          fontSize: '0.875rem',
          fontWeight: '500',
          lineHeight: '1.25rem',
          testId: 'warningBoxTimeSelector'
        }
    }
  }

  const config = getConfig()
  return (
    <Wrapper $height={config.height} data-testid={config.testId}>
      <WarningIcon icon={faTriangleExclamation} />
      <WarningText
        $fontSize={config.fontSize}
        $fontWeight={config.fontWeight}
        $lineHeight={config.lineHeight}
      >
        {text}
      </WarningText>
    </Wrapper>
  )
}
