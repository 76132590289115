import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
const Text = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`
const Line = styled.div`
  height: 0.0625rem;
  flex: 1 0 0;
  background-color: var(--text-lightest);
`
export const InjectionsRecentDaysTimeline = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Text>{t('Today')}</Text>
      <Line />
      <Text>{t('5d')}</Text>
    </Wrapper>
  )
}
