import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getConsultationsSummaryLatest } from '../../../core/api/consultations/get-consultations-summary-latest'
import dayjs from '../../../core/dayjs/dayjs'
import { SnackBar, SnackBarType } from '../../../libraries/Toast/SnackBar'
import { Modal, ModalComponent } from '../../Modal/Modal'
import { ConsultationsSummaryErrorTable } from './ConsultationsSummaryErrorTable/ConsultationsSummaryErrorTable'
import { ErrorFileComponent } from './ErrorFileComponent/ErrorFileComponent'
import { ImportedFileComponent } from './ImportedFileComponent/ImportedFileComponent'

const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
  box-sizing: border-box;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 2.25rem;
`
export const Button = styled.div`
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg-80);
  backdrop-filter: blur(0.5rem);
  height: 3.25rem;
  box-sizing: border-box;
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg-dark);
    color: var(--brand-primary-color);
  }
`
export const ButtonIcon = styled(FontAwesomeIcon)`
  color: inherit;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
`
export const ButtonText = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`

export const KeyValuePair = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1 0 0;
`

const Key = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`

const Value = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`
const ButtonWrapper = styled.div`
  padding: 0.75rem;
  display: inline-flex;
  gap: 0.375rem;
  height: 2.5rem;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--element-bg-dark);
  }
`
export const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--blue);
`
export const Text = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`

export interface ConsultationsSummaryData {
  id: string
  time: string
  importUserName: string
  uniqueRows: number
  firstConsultation: string
  lastConsultation: string
  errorSummary: string
  rowsWithErrors: number
}

interface ConsultationsSummaryProps {
  summary: ConsultationsSummaryData | undefined
  setSummary: Dispatch<SetStateAction<ConsultationsSummaryData | undefined>>
  refreshSummary: boolean
  getLatestSummary: boolean
}
export const ConsultationsSummary = ({
  summary,
  setSummary,
  refreshSummary,
  getLatestSummary
}: ConsultationsSummaryProps) => {
  useEffect(() => {
    if (!getLatestSummary) {
      return
    }
    const controller = new AbortController()
    const getSummary = async () => {
      try {
        const response = await getConsultationsSummaryLatest({
          signal: controller.signal
        })
        setSummary(response)
      } catch {
        SnackBar({
          type: SnackBarType.Error,
          message: 'error getting summary'
        })
      }
    }
    getSummary()

    return () => {
      controller.abort()
    }
  }, [setSummary, refreshSummary, getLatestSummary])

  return <ConsultationsSummaryComponent summary={summary} />
}

const NoValue = () => {
  return <Key>–</Key>
}
interface ConsultationsSummaryComponentProps {
  summary: ConsultationsSummaryData | undefined
}
export const ConsultationsSummaryComponent = ({
  summary
}: ConsultationsSummaryComponentProps) => {
  const [showSummary, setShowSummary] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <ButtonWrapper
        onClick={() => setShowSummary(true)}
        data-testid={'showConsultationsSummaryButton'}
      >
        <Icon icon={faInfoCircle}></Icon>
        <Text>
          {summary
            ? t('based_on_csv_data', {
                date: dayjs(summary.time).format('DD/MM/YYYY')
              })
            : t('based_on_csv_no_data')}
        </Text>
      </ButtonWrapper>

      <Modal isOpen={showSummary}>
        <ModalComponent
          closeFun={() => setShowSummary(false)}
          closetestid={'closeConsultationSummaryModal'}
        >
          <Wrapper>
            <TitleWrapper>
              <Title>{t('consultation_summary_title')}</Title>
            </TitleWrapper>
            <ImportedFileComponent summary={summary} />
            <Row>
              <KeyValuePair data-testid="consultation_summary_key_title_time">
                <Key>{t('consultation_summary_key_title_time')}</Key>
                {summary ? (
                  <Value>
                    {dayjs(summary.time).format('DD/MM/YYYY - H:mm')}
                  </Value>
                ) : (
                  <NoValue />
                )}
              </KeyValuePair>
              <KeyValuePair data-testid="consultation_summary_key_title_importedby">
                <Key>{t('consultation_summary_key_title_importedby')}</Key>
                {summary ? (
                  <Value>{summary.importUserName}</Value>
                ) : (
                  <NoValue />
                )}
              </KeyValuePair>
            </Row>
            <Row>
              <KeyValuePair data-testid="consultation_summary_key_title_importedrows">
                <Key>{t('consultation_summary_key_title_importedrows')}</Key>
                {summary ? <Value>{summary.uniqueRows}</Value> : <NoValue />}
              </KeyValuePair>
              <KeyValuePair data-testid="consultation_summary_key_title_daterange">
                <Key>{t('consultation_summary_key_title_daterange')}</Key>
                {summary ? (
                  <Value>
                    {dayjs(summary.firstConsultation).format('DD/MM/YYYY')} -{' '}
                    {dayjs(summary.lastConsultation).format('DD/MM/YYYY')}
                  </Value>
                ) : (
                  <NoValue />
                )}
              </KeyValuePair>
            </Row>
            <Row>
              <KeyValuePair data-testid="consultation_summary_key_title_errorrows">
                <Key>{t('consultation_summary_key_title_errorrows')}</Key>
                {summary ? (
                  <Value>{summary.rowsWithErrors}</Value>
                ) : (
                  <NoValue />
                )}
              </KeyValuePair>
              <ErrorFileComponent summary={summary} />
            </Row>
            <ConsultationsSummaryErrorTable summary={summary} />
          </Wrapper>
        </ModalComponent>
      </Modal>
    </>
  )
}
