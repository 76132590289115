import { useEffect, useState } from 'react'
import { useSiteSettingsContext } from '../core/contexts/site-settings-context'
import { Capabilities } from '../core/entities/siteSettings.entity'

export function useCapabilities() {
  const { siteSettings } = useSiteSettingsContext()
  const [capabilities, setCapabilities] = useState<string[]>([])

  useEffect(() => {
    if (siteSettings && siteSettings.capabilities) {
      setCapabilities(siteSettings.capabilities)
    }
  }, [siteSettings])

  function isCapabilitySupportedBySite(capability: Capabilities) {
    return capabilities.includes(capability)
  }

  return {
    isCapabilitySupportedBySite,
    deviceSettingsOrInfo: capabilities.includes('adaptt2d')
      ? 'device_info'
      : 'device_settings'
  }
}
