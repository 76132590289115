import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 31.25rem;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.5rem;
  background: var(--white-color);
`

const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`

const BodyText = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-wrap;
`
export const CgmDataModal = () => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="CgmDataModal">
      <Title>{t('less_than_80%_cgm_data')}</Title>
      <BodyText>
        {t(
          'In order for the dose algorithm to work, the system needs more than 80% data coverage from the CGM from the last 3 days. The AUTO suggested dose will default to the last recommended dose.\n\nYou can still manually set a suggested dose for the patient.'
        )}
      </BodyText>
    </Wrapper>
  )
}
