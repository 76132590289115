import { ConsultationsSummaryData } from '../../../components/Consultations/ConsultationsSummary/ConsultationsSummary'
import config from '../../config'
import { AxiosRequest } from '../axios-request'

export async function getConsultationsSummaryLatest(
  options: object = {}
): Promise<ConsultationsSummaryData> {
  const response = await AxiosRequest.create().get(
    config.API_URL + '/consultations_uploads/latest',
    {
      ...options,
      paramsSerializer: {
        indexes: null
      }
    }
  )
  return response.data as ConsultationsSummaryData
}
