import { faCalendar, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowRightLong,
  faHistory,
  faWarning
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePeriodContext } from '../../../core/contexts/period-context'
import dayjs from '../../../core/dayjs/dayjs'
import { Modal } from '../../Modal/Modal'
import { TimeSelector, timeDataObject } from '../../TimeSelector/TimeSelector'
import { PeriodObject } from '../DevicesAndPeriod/DevicesAndPeriod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  border-right: 0.0625rem solid var(--text-lightest);
  color: var(--text-lighter);
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--element-bg);
  }
`
const Arrow = styled(FontAwesomeIcon)<{ $hovered: boolean }>`
  color: var(--brand-primary-color);
  font-size: 0.6875rem;
  font-weight: 900;
  display: ${(props) => (props.$hovered ? 'block' : 'none')};
`
const Title = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`

const OldDataWrapper = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
  padding: 0.75rem 1rem;
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  position: absolute;
  top: 2rem;
  white-space: nowrap;
  z-index: 100;
`
const TitleAndIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  position: relative;
`
const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: var(--yellow);
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items center;
  justify-content: space-between;

`
const ButtonWrapper = styled.div<{ $oldData: boolean }>`
  border: ${(props) =>
    props.$oldData
      ? '0.0625rem solid var(--yellow)'
      : '0.0625rem solid var(--text-lightest)'};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

const ButtonElement = styled.div<{ $hovered: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  background-color: ${(props) =>
    props.$hovered ? 'var(--white-color)' : 'var(--element-bg)'};
`

const ButtonElementWithBorder = styled(ButtonElement)<{ $oldData: boolean }>`
  border-right: ${(props) =>
    props.$oldData
      ? '0.0625rem solid var(--yellow)'
      : '0.0625rem solid var(--text-lightest)'};
`
const ButtonElementIcon = styled(FontAwesomeIcon)<{ $oldData?: boolean }>`
  color: ${(props) =>
    props.$oldData ? 'var(--yellow)' : 'var(--text-primary)'};
  font-size: 0.6875rem;
`
const ButtonElementText = styled.span<{ $oldData?: boolean }>`
  color: ${(props) =>
    props.$oldData ? 'var(--yellow)' : 'var(--text-primary)'};
  font-size: 0.75rem;
  font-family: inter;
  font-weight: 600;
`

interface TimePeriodComponentProps {
  timeData: timeDataObject[] | null
  setPeriodState: Dispatch<SetStateAction<PeriodObject | null>>
  period: PeriodObject | null
  emptyPatient: boolean
  oldData: boolean
}
export const TimePeriodComponent = ({
  timeData,
  setPeriodState,
  period,
  emptyPatient,
  oldData
}: TimePeriodComponentProps) => {
  const { t } = useTranslation()
  const { setPeriod } = usePeriodContext()
  const [showTimeSelector, setShowTimeSelector] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)
  const [showOldDataWarning, setShowOldDataWarning] = useState(false)
  const handlePeriod = (state: PeriodObject) => {
    setPeriodState(state)
    setPeriod(state)
    setShowTimeSelector(!showTimeSelector)
  }

  return (
    <Wrapper
      data-testid="timeperiodcomponent"
      onClick={() => {
        setShowTimeSelector(true)
        setHovered(false)
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Modal isOpen={showTimeSelector}>
        <TimeSelector
          handleTimeSelector={() => setShowTimeSelector(false)}
          icon={faInfoCircle}
          handlePeriod={handlePeriod}
          period={emptyPatient ? null : period}
          timeData={timeData}
          oldData={oldData}
        ></TimeSelector>
      </Modal>
      <TitleWrapper>
        <TitleAndIcon>
          <Title>{t('Time Period')}</Title>
          {oldData && (
            <WarningIcon
              onMouseEnter={() => setShowOldDataWarning(true)}
              onMouseLeave={() => setShowOldDataWarning(false)}
              data-testid="oldDataWarningIcon"
              icon={faWarning}
            ></WarningIcon>
          )}
          {showOldDataWarning && (
            <OldDataWrapper data-testid="oldDataWarning">
              <ButtonElementText>
                {t('latest_data_14_days_old')}
              </ButtonElementText>
            </OldDataWrapper>
          )}
        </TitleAndIcon>
        <Arrow icon={faArrowRightLong} $hovered={hovered} />
      </TitleWrapper>

      <ButtonWrapper $oldData={oldData}>
        <ButtonElementWithBorder $oldData={oldData} $hovered={hovered}>
          <ButtonElementIcon icon={faHistory} />
          <ButtonElementText>
            {period !== null
              ? `${period.lengthOfPeriod} ${t('days')}`
              : t('NO DATA')}
          </ButtonElementText>
        </ButtonElementWithBorder>
        <ButtonElement $hovered={hovered}>
          <ButtonElementIcon icon={faCalendar} $oldData={oldData} />
          <ButtonElementText data-testid="periodActiveDate" $oldData={oldData}>
            {period !== null
              ? `${
                  dayjs(period.endDate).isToday()
                    ? t('Today')
                    : dayjs(period.endDate).format('DD/MM/YYYY')
                }`
              : t('NO DATA')}
          </ButtonElementText>
        </ButtonElement>
      </ButtonWrapper>
    </Wrapper>
  )
}
