import {
  faArrowUpFromArc,
  faLinkSimple
} from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenuItem } from '../../../ContextMenu/ContextMenu'
import { Modal, ModalComponent } from '../../../Modal/Modal'
import { ConsultationsAccountButtonProps } from '../../constants/constants'
import {
  HoverableWrapper,
  Icon,
  Text
} from '../ConsultationsAccountLinkStyledComponents/ConsultationsAccountLinkStyledComponents'
import { AbbottLibreviewConsultationsModal } from './AbbottLibreviewConsultationsModal'

export const AbbottLibreviewConsultationsButton = ({
  provider,
  row,
  inOptionsMenu
}: ConsultationsAccountButtonProps) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={showModal}>
        <ModalComponent
          closeFun={() => setShowModal(false)}
          closetestid={'closeAbbottLibreviewConsultationsModal'}
        >
          <AbbottLibreviewConsultationsModal
            link={`https://www.libreview.com/patient/${provider.providerPatientId}/profile`}
            provider={provider}
            row={row}
          />
        </ModalComponent>
      </Modal>
      {inOptionsMenu ? (
        <ContextMenuItem
          onClick={() => setShowModal(true)}
          icon={faArrowUpFromArc}
          data-testid="showAbottLibreviewModalFromOptions"
        >
          {t('Upload LibreView file')}
        </ContextMenuItem>
      ) : (
        <HoverableWrapper
          onClick={() => setShowModal(true)}
          data-testid="showAbottLibreviewModalFromTable"
        >
          <Icon icon={faLinkSimple}></Icon>
          <Text>
            {t(
              `data-filter.patientaccountlink.${provider.providerType.toLowerCase()}`
            )}
          </Text>
        </HoverableWrapper>
      )}
    </>
  )
}
