export const TIME_PERIOD_MONTHS = 3

export enum dataState {
  empty = '–',
  hidden = '',
  options = 'options'
}

export enum FileUploadType {
  legacystenopool = 'legacystenopool',
  medtroniccarelink = 'medtroniccarelink',
  abbottlibreview = 'abbottlibreview',
  dexcomclarity = 'dexcomclarity'
}

export enum ApplicationUploadPlatform {
  ios = 'ios',
  mac = 'mac',
  windows = 'windows',
  android = 'android',
  legacy = 'legacy'
}

export enum ApplicationUploadActivityType {
  App = 'App',
  ClinicUploader = 'ClinicUploader',
  Cloud = 'Cloud',
  File = 'File',
  HomeUploader = 'HomeUploader',
  Legacy = 'Legacy'
}

export enum Manufacturer {
  Abbott = 'Abbott',
  Tandem = 'Tandem',
  ISENS = 'ISENS',
  Bayer = 'Bayer',
  Insulet = 'Insulet',
  Medtronic = 'Medtronic',
  Dexcom = 'Dexcom',
  Roche = 'Roche',
  AMenarini = 'AMenarini',
  Google = 'Google',
  Novo = 'Novo',
  Cloud = 'Cloud'
}

export type UploadContext =
  | {
      type: ApplicationUploadActivityType.ClinicUploader
      applicationPlatform: ApplicationUploadPlatform | null
      applicationPlatformString: string | null
      applicationUploadVersion: string | null
    }
  | {
      type: ApplicationUploadActivityType.HomeUploader
      applicationPlatform: ApplicationUploadPlatform | null
      applicationPlatformString: string | null
      applicationUploadVersion: string | null
    }
  | {
      type: ApplicationUploadActivityType.Legacy
      applicationPlatform: ApplicationUploadPlatform | null
      applicationPlatformString: string | null
      applicationUploadVersion: string | null
    }
  | {
      type: ApplicationUploadActivityType.App
      applicationPlatform: ApplicationUploadPlatform | null
      applicationPlatformString: string | null
      applicationUploadVersion: string | null
    }
  | {
      type: ApplicationUploadActivityType.Cloud
      providerName: string | null
    }
  | {
      type: ApplicationUploadActivityType.File
      fileUploadType: FileUploadType | null
    }

export interface UploadUser {
  id: string
  type: string
  name: string
  keyCloakUserId: string | null
  upid: string | null
  patientId: string | null
}

export interface DeviceContext {
  device: string
  modelDisplayName: string
  deviceType: string
}
export interface Upload {
  id: string
  patientId: string
  patientMRN: string
  patientName: string
  uploadType: ApplicationUploadActivityType
  uploaded: string
  uploadContext: UploadContext
  deviceContext: DeviceContext
  user?: UploadUser
  manufacturer: string
}

export interface Filters {
  searchString?: string
}

export enum Filter {
  cutoff = 'cutoff',
  types = 'types',
  manufacturers = 'manufacturers',
  searchString = 'searchString'
}
