import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  TitleComponent,
  TitleWrapperComponent
} from '../../DataWidget/Shared/Header/Header'
import { WidgetState } from '../../DataWidget/Shared/Interfaces/Interfaces'
import {
  Container,
  getActualState,
  Wrapper
} from '../../SingleWidget/SingleWidget'
import { AvgForDaysInjected } from './AvgForDaysInjected/AvgForDaysInjected'
import { InjectionsMade } from './InjectionsMade/InjectionsMade'

export interface BasalInjectionWidgetData {
  daysWithInjections: number
  daysWithInjectionsPercentage: number
  daysWithInjectionsAvgBasal: number
  totalDays: number
}
interface BasalInjectionProps {
  state: WidgetState
  data: BasalInjectionWidgetData
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`

export const BasalInjection = ({ state, data }: BasalInjectionProps) => {
  const { t } = useTranslation()
  const actualState = getActualState(state, data)
  return (
    <Container>
      <Wrapper>
        <TitleWrapperComponent>
          <TitleComponent>{t('Basal Doses')}</TitleComponent>
        </TitleWrapperComponent>
      </Wrapper>
      <Body>
        {/* <DoseRecommendationPending /> */}
        <InjectionsMade data={data} />
        <AvgForDaysInjected data={data} />
      </Body>
    </Container>
  )
}
