import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PenInjectionsWidgetObject } from '../../../../containers/StackedDaily/Interfaces/Interfaces'
import {
  TitleComponent,
  TitleWrapperComponent
} from '../../../DataWidget/Shared/Header/Header'
import { WidgetState } from '../../../DataWidget/Shared/Interfaces/Interfaces'
import {
  getInformationTooltipParagraph,
  TooltipType
} from '../../../InformationTooltip/InformationTooltipParagraphs'
import {
  BasalInjectionIcon,
  BasalInjectionsIconSize
} from '../BasalInjectionIcon/BasalInjectionIcon'

const Wrapper = styled.div`
  gap: 0.25rem;
  width: 17.625rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0.5rem;
`
const Body = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
`
const KeyValueColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.75rem 1rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--element-bg);
  flex: 1 0 0;
`

const KeyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
`
const KeyIconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const KeyIcon = styled(FontAwesomeIcon)<{ $color: string }>`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.$color};
`

const KeyValueText = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`

const KeyValueAmount = styled(KeyValueText)`
  color: var(--text-lighter);
`
const ValueWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
`

const ValueText = styled.span`
  font-family: inter;
  color: var(--text-primary);
  line-height: 1rem;
  text-align: right;
  font-size: 1rem;
  font-weight: 700;
`
const ValueUnitText = styled.span`
  font-family: inter;
  font-size: 0.625rem;
  color: var(--text-lighter);
  font-weight: 700;
  line-height: 0.625rem;
`

const NoData = styled.span`
  color: var(--text-lighter);
  text-align: center;
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
`
interface PenInjectionsWidgetProps {
  data: PenInjectionsWidgetObject | null
  state: WidgetState
}
export const PenInjectionsWidget = ({
  data,
  state
}: PenInjectionsWidgetProps) => {
  const { t } = useTranslation()
  const tooltip = getInformationTooltipParagraph(TooltipType.penInjections, t)
  const getActualState = () => {
    if (state === WidgetState.Loading) {
      return state
    } else {
      if (
        data === null ||
        (data.totalBasalInjections === null &&
          data.totalBasalVolume === null &&
          data.totalBolusInjections === null &&
          data.totalBolusVolume === null)
      ) {
        return WidgetState.Empty
      } else return WidgetState.Data
    }
  }
  const actualState = getActualState()

  const getBody = () => {
    if (data === null) {
      return (
        <Body>
          <KeyValueColumn>
            <KeyWrapper>
              <KeyValueText>{t('Basal')}</KeyValueText>
            </KeyWrapper>
            <NoData>{t('NO DATA')}</NoData>
          </KeyValueColumn>

          <KeyValueColumn>
            <KeyWrapper>
              <KeyValueText>{t('Bolus')}</KeyValueText>
            </KeyWrapper>
            <NoData>{t('NO DATA')}</NoData>
          </KeyValueColumn>
        </Body>
      )
    } else {
      const basalVolumeIs0 = data.totalBasalVolume === 0
      return (
        <Body>
          {data.totalBasalInjections !== null ? (
            <KeyValueColumn>
              <KeyWrapper>
                <BasalInjectionIcon
                  injected={!basalVolumeIs0}
                  size={BasalInjectionsIconSize.small}
                />
                <KeyValueText>{t('Basal')}</KeyValueText>
                <KeyValueAmount>({data.totalBasalInjections})</KeyValueAmount>
              </KeyWrapper>
              <ValueWrapper>
                <ValueText
                  data-testid="basalVolumeValue"
                  style={{ color: 'var(--basal-color)' }}
                >
                  {data.totalBasalVolume}
                </ValueText>
                <ValueUnitText>U {t('total')}</ValueUnitText>
              </ValueWrapper>
            </KeyValueColumn>
          ) : (
            <KeyValueColumn data-testid="penInjectionWidgetBasalNoData">
              <KeyWrapper>
                <KeyValueText>{t('Basal')}</KeyValueText>
              </KeyWrapper>
              <NoData>{t('NO DATA')}</NoData>
            </KeyValueColumn>
          )}

          {data.totalBolusInjections !== null ? (
            <KeyValueColumn>
              <KeyWrapper>
                <KeyValueText>{t('Bolus')}</KeyValueText>
                <KeyValueAmount>({data.totalBolusInjections})</KeyValueAmount>
              </KeyWrapper>
              <ValueWrapper>
                <ValueText
                  data-testid="bolusVolumeValue"
                  style={{ color: 'var(--bolus-color)' }}
                >
                  {data.totalBolusVolume}
                </ValueText>
                <ValueUnitText>U {t('total')}</ValueUnitText>
              </ValueWrapper>
            </KeyValueColumn>
          ) : (
            <KeyValueColumn data-testid="penInjectionWidgetBolusNoData">
              <KeyWrapper>
                <KeyValueText>{t('Bolus')}</KeyValueText>
              </KeyWrapper>
              <NoData>{t('NO DATA')}</NoData>
            </KeyValueColumn>
          )}
        </Body>
      )
    }
  }
  return (
    <Wrapper data-testid="penInjectionsWidget">
      <TitleWrapperComponent>
        <TitleComponent>{t('Pen Doses')}</TitleComponent>
      </TitleWrapperComponent>
      {getBody()}
    </Wrapper>
  )
}
