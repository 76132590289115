import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDataFilter } from '../../hooks/useDataFilter'
import {
  DataFilter,
  DataFilterType
} from '../TableComponents/Filters/DataFilter/DataFilter'
import { FilterFunctionButton } from '../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import {
  FilterSection,
  FiltersWrapper,
  TopRight
} from '../TableComponents/Filters/FilterStyledElements/FilterStyledElements'
import {
  ApplicationUploadActivityType,
  Filter,
  Manufacturer
} from './constants'

export function UploadActivityFilters({
  defaults,
  currentFilters,
  onChange
}: {
  defaults: { [key: string]: string[] | string }
  currentFilters: { [key: string]: string[] | string }
  onChange: (values: { [key: string]: string[] | string }) => void
}) {
  const { t } = useTranslation()
  const isFirstRender = useRef(true)

  const typesFilter = useDataFilter(
    Object.values(ApplicationUploadActivityType),
    defaults[Filter.types],
    currentFilters[Filter.types]
  )
  const manufacturersFilter = useDataFilter(
    Object.values(Manufacturer),
    defaults[Filter.manufacturers],
    currentFilters[Filter.manufacturers]
  )

  const filters = useMemo(() => {
    return {
      [Filter.types]: typesFilter.selected,
      [Filter.manufacturers]: manufacturersFilter.selected
    }
  }, [typesFilter.selected, manufacturersFilter.selected])

  const resetFilters = () => {
    typesFilter.reset()
    manufacturersFilter.reset()
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    onChange(filters)
  }, [filters, onChange])

  return (
    <FiltersWrapper>
      <FilterSection>
        <DataFilter
          title={t('Context')}
          filter={typesFilter}
          slug={Filter.types}
          type={DataFilterType.multiSelect}
        />
        <DataFilter
          title={t('Manufacturers')}
          filter={manufacturersFilter}
          slug={Filter.manufacturers}
          type={DataFilterType.multiSelect}
        />
      </FilterSection>
      <TopRight>
        <FilterFunctionButton
          handleClick={resetFilters}
          icon={faRotateRight}
          text={t('Reset to defaults')}
        />
      </TopRight>
    </FiltersWrapper>
  )
}
